.form {

  &__group {
    margin-bottom: 20px;
    @media (max-width: 1480px) {
      margin-bottom: 16px;
    }
  }

  .input {
    width: 100%;
    height: 54px;
    background-color: #f7f7f7;
    padding-left: 25px;
    color: $color-black;
    font-size: 16px;
    font-weight: 400;
    font-family: $font-primary;

    &::placeholder {
      color: $color-grey;
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: left;
    }

    @media (max-width: 1480px) {
      font-size: 15px;
      height: 50px;
      &::placeholder {
        font-size: 15px;
      }
    }
  }

  .input-border {
    background-color: transparent;
    border: 1px solid #e7e7e7;
  }

  .textarea {
    width: 100%;
    min-height: 180px;
    max-height: 450px;
    resize: vertical;
    background-color: #f7f7f7;
    padding: 25px;
    color: $color-black;
    font-size: 16px;
    font-weight: 400;
    font-family: $font-primary;

    &::placeholder {
      color: $color-grey;
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: left;
    }

    @media (max-width: 1480px) {
      font-size: 15px;
      height: 50px;
      &::placeholder {
        font-size: 15px;
      }
    }
  }

  .wpcf7-not-valid-tip{
    font-size: 0.8em;
  }

  .wpcf7 {
    form{
      .wpcf7-response-output{
        margin-left: 0;
        margin-right: 0;
      }
    }
  }


  &__title {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  &__notice {
    margin-bottom: 25px;
    color: $color-grey;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.625;
  }

  button[type=submit] {
    width: 100%;
  }
}

.header-form-wrap {
  background-color: $color-white;
  min-width: 425px;
  width: 425px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.09);
  border-radius: 25px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 42px;
  padding-bottom: 42px;
}

// Insight Form
.insight-form-wrap {
  width: 1080px;
  max-width: calc(100% - 30px);
  margin-left: auto;
  margin-right: auto;
  height: 146px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.13);
  background-color: $color-white;
  position: relative;
  z-index: 15;
  display: flex;
  align-items: center;

  .form {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    .wpcf7-not-valid-tip{
      display: none;
    }
    form{
      .ajax-loader{
        display: none;
      }
      &>p{
        display: flex;
        align-items: center;
        width: 100%;
      }
      .wpcf7-response-output{
        display: none;
      }
    }

    .input {
      height: 66px;
    }

    &__group {
      margin: 0;
      margin-right: 15px;
      flex: 1;
    }

    button[type=submit] {
      max-width: 235px;
      height: 66px;
      min-height: 66px;
    }
  }

  @media (max-width: 991px) {
    max-width: 100%;
    .form {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  @media (max-width: 767px) {
    height: auto;
    padding: 30px 0;
    .form {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
      form{
        &>p {
          flex-wrap: wrap;
          width: 100%;
          justify-content: center;
          align-items: center;
        }
      }
      
      &__group {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        flex: unset;
        margin-bottom: 20px;
      }
    }
  }
}
