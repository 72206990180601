.results-in-numbers {
  padding: 70px 0;

  .result-boxes {
    display: flex;
    flex-wrap: wrap;
    margin-left: -44px;
    margin-right: -44px;

    .result-box {
      width: calc(100% / 3 - 88px);
      margin-left: 44px;
      margin-right: 44px;
      margin-top: 20px;
      margin-bottom: 20px;
      border: 1px solid;
      background-color: #fdfdfd;
      padding: 65px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__percent {
        text-align: center;
        font-weight: bold;
        font-style: normal;
        letter-spacing: normal;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 65px;

        &:after {
          content: "";
          display: block;
          width: 135px;
          height: 2px;
          margin-bottom: 20px;
          margin-top: 12px;
        }

        span {
          font-size: 70%;
          transform: translateY(-13%);
          margin-left: 4px;
        }
      }

      &__heading {
        text-align: center;
        color: $color-black;
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        letter-spacing: normal;
        line-height: 1.35;
        text-transform: uppercase;
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;

      }

      &:nth-child(3n+1) {
        border-color: $color-orange-light;

        .result-box__percent {
          color: $color-orange-light;

          &:after {
            background-color: $color-orange-light;
          }
        }
      }

      &:nth-child(3n+2) {
        border-color: $color-green-2;

        .result-box__percent {
          color: $color-green-2;

          &:after {
            background-color: $color-green-2;
          }
        }
      }

      &:nth-child(3n+3) {
        border-color: $color-orange;

        .result-box__percent {
          color: $color-orange;

          &:after {
            background-color: $color-orange;
          }
        }
      }
    }
  }

  @media (max-width: 1440px) {
    .result-boxes {
      margin-left: 0;
      margin-right: 0;

      .result-box {
        width: calc(100% / 3 - 40px);
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
  @media (max-width: 1199px) {
    .result-boxes {
      .result-box {
        padding: 50px 15px;
      }
    }
  }
  @media (max-width: 991px) {
    .result-boxes {
      margin-left: -7.5px;
      margin-right: -7.5px;
      .result-box {
        width: calc(100% / 3 - 15px);
        margin-left: 7.5px;
        margin-right: 7.5px;
        margin-top: 7.5px;
        margin-bottom: 7.5px;
        padding: 40px 15px;
        &__heading {
          font-size: 18px;
        }
      }
    }
  }
  @media (max-width: 660px) {
    padding: 60px 0;
    .result-boxes{
      justify-content: center;
      .result-box{
        width: calc(50% - 15px);
      }
    }
  }
  @media (max-width: 480px) {
   .result-boxes{
     .result-box{
       width: 100%;
       padding-top: 25px;
       &__percent{
         font-size: 50px;
       }
       &__heading{
         max-width: 100%;
         font-size: 20px;
         br{
           display: none;
         }
       }
     }
   }
  }
}
