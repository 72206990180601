.page-header {
  height: 100vh;
  max-height: 650px;
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;

  &__large{
    min-height: 725px;
  }

  &__bg {
    pointer-events: none;
    img {
      z-index: 10;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }

  .overlay{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    background: rgba(0, 0, 0, 0.4);
  }

  .video{
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    z-index: 11;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
  }

  .container {
    position: relative;
    z-index: 15;
    height: 100%;
  }
  &__row {
    padding-top: 65px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__subheading {
    text-align: center;
    margin-bottom: 20px;
  }

  &__heading {
    text-align: center;
    color: $color-white;
    letter-spacing: 1.2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .heading-inner {
      font-weight: 400;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &:before {
        position: absolute;
        right: 100%;
        display: inline-block;
        content: "";
        width: 95px;
        height: 2px;
        background-color: $color-white;
        margin-right: 25px;
      }

      &:after {
        position: absolute;
        left: 100%;
        display: inline-block;
        content: "";
        width: 95px;
        height: 2px;
        background-color: $color-white;
        margin-left: 25px;
      }
    }
  }

  &__caption {
    max-width: 512px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 22px;
    color: rgba($color-white, .95);
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
  }

  .btn-wrap {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .btn {
      margin: 0 10px;
    }
  }

  @media (max-width: 1366px) {
    max-height: 600px;
    &__large{
      min-height: 640px;
    }
    &__bg {
      img {
        object-position: left center;
      }
    }
  }
  @media (max-width: 991px) {
    min-height: unset;
    height: auto;
    max-height: unset;
    padding-bottom: 120px;
    padding-top: 165px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .container {
      height: auto;
    }
    &__row {
      height: auto;
      padding: 0;
    }
  }
  @media (max-width: 660px) {
    padding-bottom: 60px;
    padding-top: 120px;
    .btn-wrap {
      .btn {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
      }
    }
  }
  @media (max-width: 480px) {
    min-height: unset;
    &__heading {
      font-size: 26px;
    }
    &__subheading {
      font-size: 16px;
    }
    &__caption {
      font-size: 16px;
    }
  }
  @media (max-width: 345px) {
    &__heading {
      font-size: 26px;
    }
  }

}




