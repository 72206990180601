.section-webinars {
  padding-top: 100px;
  padding-bottom: 110px;

  .webinars {
    margin-top: 85px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-right: -30px;
    .webinar {
      width: calc(50% - 60px);
      margin-left: 30px;
      margin-right: 30px;
      margin-bottom: 70px;
      &__thumbnail{
        position: relative;
        &:after{
          display: block;
          content: "";
          padding-bottom:65%;
        }
        img{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .type{
          position: absolute;
          z-index: 15;
          top: 26px;
          left: -10px;
          box-shadow: 0 0 40px rgba(0, 0, 0, 0.13);
          background-color: $color-orange-light;
          color: $color-white;
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          letter-spacing: 0.64px;
          line-height: normal;
          text-transform: uppercase;
          &__inner{
            width: 150px;
            height: 34px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            &:before{
              display: block;
              content: "";
              background-image: url(../img/webinar-angle.svg);
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              width: 10px;
              height: 6px;
              position: absolute;
              bottom: 0;
              left: 0;
              transform: translateY(100%);
            }
          }
        }
      }

      &__info{
        background-color: $color-white;
        width: calc(100% - 75px);
        margin-top: -60px;
        z-index: 15;
        position: relative;
        padding-top: 35px;
        .btn{
          height: 56px;
        }
      }

      &__title{
        margin-bottom: 35px;
        text-transform: uppercase;
      }


    }
  }


  @media (max-width: 1199px) {
    padding-top: 80px;
    padding-bottom: 80px;
    .webinars{
      margin-top: 50px;
      margin-left: -20px;
      margin-right: -20px;
      .webinar{
        width: calc(50% - 40px);
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 50px;
        &__info{
          width: calc(100% - 15px);
          padding-top: 30px;
        }
        &__title{
          margin-bottom: 30px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .webinars{
     .webinar{
       &__info{
         margin-top: 0;
         padding-top: 15px;
       }
       &__title{
         margin-bottom: 15px;
         font-size: 18px;
       }
     }
    }
  }

  @media (max-width: 767px) {
    .webinars{
      margin-left: 0;
      margin-right: 0;
      .webinar{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 70px;
        &:last-child{
          margin-bottom: 0;
        }
        &__info{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      &__title{
        text-align: center;
      }
      }
    }
  }

}
