.cta-section {
  position: relative;
  padding: 113px 0;
  .bg {
    pointer-events: none;
    img {
      z-index: 10;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }

  .container{
    position: relative;
    z-index: 15;
  }

  .logo-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 23px;
    img{
      max-width: 120px;
      height: auto;
      display: block;
      object-fit: contain;
    }
  }

  .section-content{
    margin-top: 12px;
    a{
      color: $color-orange-light;
      &:hover{
        text-decoration: underline;
      }
    }
  }

  .btn-wrap{
    margin-top: 27px;
  }

  @media (max-width: 767px) {
    padding: 75px 0;
  }
  @media (max-width: 660px) {
    .section-content{
      max-width: 280px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (max-width: 480px) {
    .section-title{
      font-size: 7vw;
    }
  }
}
