.section-socials {
  padding: 105px 0;
  background-color: #f6f6f6;
  .grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border: 1px solid #e7e7e7;
    &__item{
      z-index: 10;
      width: 25%;
      min-height: 125px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border: 1px solid #e7e7e7;
      background-color: #ffffff;
      backface-visibility: hidden;
      transform: scale(1);
      @include transition;
      &:hover{
        z-index: 15;
        transform: scale(1.08);
        box-shadow: 3px 4px 25px rgba(0, 0, 0, 0.05);
      }
      img{
        display: block;
        object-fit: contain;
        max-width: 65%;
        height: auto;
      }
    }
  }
  @media (max-width: 991px) {
    padding: 80px 0;
    .grid{
      &__item{
        min-height: 155px;
        width: 50%;
      }
    }
  }
  @media (max-width: 660px) {
    padding: 60px 0;
    .grid{
      &__item{
        img{
          max-width: 80%;
        }
      }
    }
  }
}
