.content-section {
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid rgba($color-black, .1);
  padding-top: 120px;
  padding-bottom: 95px;
  .content {
    margin-top: 26px;
  }
  .section-title {
    &:after {
      margin-left: 0;
      margin-right: 0;
      left: 0;
      position: static;
      transform: none;
      margin-top: 24px;
    }
  }
  @media (max-width: 1199px) {
    padding: 80px 0;
  }
  @media (max-width: 767px) {
    padding: 60px 0;
  }
}

.page-seo-ebook {
  .content-section {
    padding-top: 165px;
    @media (max-width: 1199px) {
      padding-top: 90px;
    }
    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }
}
