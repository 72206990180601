.section-cases {
  padding-top: 120px;
  padding-bottom: 110px;

  .section-title {
    text-align: center;
  }

  .cases-grid {
    margin-top: 56px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;

    .case-item {
      width: calc(100% / 3 - 30px);
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 30px;
      border: 1px solid #d6d6d6;
      background-color: $color-white;
      padding-bottom: 40px;
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 45px;

      &__logo {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 55px;
        width: 135px;
        position: relative;

        &:after {
          position: absolute;
          display: block;
          content: "";
          border-bottom: 2px solid $color-orange-light;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        img {
          max-width: 130px;
          max-height: 53px;
          object-fit: contain;
          display: block;
        }
      }

      &__heading {
        color: #6cad4e;
        font-size: 50px;
        font-weight: bold;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        text-transform: uppercase;
        margin-top: 30px;
        margin-bottom: 10px;
        line-height: 1;
        display: block;
      }

      &__subheading {
        text-align: center;
        display: block;
        margin-bottom: 16px;
        color: $color-black-lighter;
      }

      &__description {
        text-align: center;
        color: $color-grey;
        font-size: 17px;
      }

      &__permalink {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: $color-orange;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 0.6px;

        .caption {
          position: relative;
          line-height: 1;

          &:after {
            display: block;
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
            width: 100%;
            height: 1px;
            background-color: $color-orange;
            @include transition;
          }
        }

        .icon {
          margin-left: 6px;
          @include transition;

          svg {
            transform: translateY(-2px);
            height: 12px;
            width: auto;
            display: block;
          }
        }

        &:hover {
          color: $color-orange-light;

          .caption {
            &:after {
              background-color: $color-orange-light;
            }
          }

          .icon {
            transform: translateX(4px);
          }
        }
      }
    }
  }

  .btn-wrap {
    margin-top: 15px;
  }

  @media (max-width: 1199px) {
    .cases-grid {
      margin-left: -10px;
      margin-right: -10px;

      .case-item {
        width: calc(100% / 3 - 20px);
        margin-left: 10px;
        margin-right: 10px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 40px;
        padding-top: 35px;

        &__logo {
          img {
            max-height: 40px;
          }
        }

        &__heading {
          font-size: 40px;
        }

        &__description {
          font-size: 16px;
        }
      }
    }
  }
  @media (max-width: 991px) {
    padding-top: 80px;
    .cases-grid {
      justify-content: center;
      .case-item {
        width: calc(100% / 2 - 20px);
      }
    }
  }
  @media (max-width: 660px) {
    .cases-grid {
      .case-item {
        max-width: 420px;
        width: calc(100% - 20px);
      }
    }
  }
}
