.container {
  width: 100%;
  padding-right: $grid-gap / 2;
  padding-left: $grid-gap / 2;
  margin: 0 auto;
}

@media (max-width: 575px) {
  .container {
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .container {
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1200px;
    max-width: 100%;
  }
}

.container-fluid {
  width: 100%;
  padding-right: $grid-gap / 2;
  padding-left: $grid-gap / 2;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$grid-gap / 2;
  margin-left: -$grid-gap / 2;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.hidden-xs-up {
  display: none !important
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important
  }
}

@media (max-width: 991px) {
  html .hidden-md-down {
    display: none !important
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important
  }
}

@media (max-width: 1199px) {
  html .hidden-lg-down {
    display: none !important
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important
  }
}

.hidden-xl-down {
  display: none !important
}
