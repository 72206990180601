.section-result-lists {
  padding: 100px 0;
  .lists-grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;

    .list-item {
      width: calc(100% / 2 - 30px);
      margin-left: 15px;
      margin-right: 15px;
      border: 1px solid #e9e9e9;
      padding-left: 35px;
      padding-right: 25px;
      padding-top: 40px;
      padding-bottom: 35px;

      .section-title {
        &:after {
          margin-top: 18px;
        }
      }
    }

    .list {
      margin-top: 25px;

      &__item {
        display: inline-flex;
        margin-bottom: 15px;
        line-height: 1.75;

        &:last-child {
          margin-bottom: 0;
        }

        .icon {
          margin-right: 8px;
          min-width: 18px;
          width: 18px;
          height: 18px;
          transform: translateY(3px);
        }

        color: $color-grey;
        font-size: 17px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
      }
    }
  }
  @media (max-width: 991px) {
    padding: 80px 0;
    .lists-grid {
      margin-left: auto;
      margin-right: auto;

      .list-item {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 35px;
        padding-left: 20px;
        padding-right: 20px;
                &:last-child {
          margin-bottom: 0;
        }

        .list{
          &__item{
            font-size: 16px;
          }
        }


      }
    }
  }


  &[data-padding-top=""]{
    padding-top: 0;
  }
}
