.section-news {
  padding-top: 115px;
  padding-bottom: 100px;

  .posts-masonry {
    margin-top: 70px;
    display: flex;
    flex-wrap: wrap;

    .post {
      min-height: 275px;
      position: relative;
      margin-bottom: 20px;
      padding-bottom: 40px;
      padding-left: 38px;
      padding-right: 30px;
      padding-top: 30px;
      display: flex;
      align-items: flex-end;

      .permalink {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 15;
      }

      .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        position: relative;
        z-index: 12;

        .post-title {
          color: $color-white;
          font-size: 22px;
          font-weight: normal;
        }

        .post-date {
          color: $color-white;
          font-size: 15px;
          font-weight: normal;
          margin-bottom: 8px;
        }
      }

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 5;
      }

      &:after {
        display: block;
        content: "";
        background-color: rgba(#000, .15);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
      }

      &:nth-child(4n+1) {
        width: calc(35% - 10px);
        margin-right: 20px;
      }

      &:nth-child(4n+2) {
        width: calc(65% - 10px);
      }

      &:nth-child(4n+3) {
        margin-right: 20px;
        width: calc(65% - 10px);
      }

      &:nth-child(4n+4) {
        width: calc(35% - 10px);
      }
    }
  }
  .posts-grid{
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
  }

  @media (max-width: 1199px) {
    padding-top: 90px;
    padding-bottom: 80px;
    .posts-masonry {
      .post {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 20px;

        .content {
          .post-title {
            font-size: 20px;
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    .posts-masonry {
      margin-left: -10px;
      margin-right: -10px;
      .post {
        width: calc(50% - 20px) !important;
        margin-left: 10px !important;
        margin-right: 10px !important;
        .content {
          .post-title {
            font-size: 20px;
          }
        }
      }
    }
  }
  @media (max-width: 565px) {
    .posts-masonry {
      margin-left: auto !important;
      margin-right: auto !important;
      .post {
        width: 100% !important;
        margin-left: auto !important;
        margin-right: auto !important;
        min-height: 200px;
        .content {
          .post-title {
            font-size: 18px;
          }
        }
      }
    }
  }
}
