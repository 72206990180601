.section-campaigns {
  padding: 110px 0;
  background-color: rgba(#f6f6f6, .6);
  .section-title{
    &:after{
      margin-top: 4px;
    }
  }

  .campaigns-grid {
    margin-top: 80px;
  }

  .campaign-row {
    display: flex;
    position: relative;
    margin-bottom: 100px;

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      .col-content {
        left: 0;
        right: auto;
      }
    }

    .col-photo {
      width: 70%;
      position: relative;
      overflow: hidden;

      .type {
        height: 30px;
        width: 200px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $color-green-2;
        box-shadow: 15px 26px 40px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #ffffff;
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        line-height: normal;
        transform: rotate(-45deg) translateY(-29%) translateX(-28%);
        z-index: 15;
      }
    }

    .col-content {
      width: 46%;
      right: 0;
      bottom: 0;
      position: absolute;
      box-shadow: 7px 12px 40px rgba(0, 0, 0, 0.06);
      border: 1px solid #e9e9e9;
      background-color: #ffffff;
      padding-left: 40px;
      padding-right: 50px;
      padding-top: 50px;
      padding-bottom: 45px;
      transform: translateY(35px);

      .content-title {
        &:after {
          margin: 20px 0;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    padding: 80px 0;
    .campaign-row {
      margin-bottom: 60px;

      .col-photo {
        width: 50%;

        img {
          min-height: 100%;
          object-fit: cover;
        }
      }

      .col-content {
        position: static;
        width: 50%;
        transform: none;
      }
    }
  }

  @media (max-width: 991px) {
    .campaigns-grid{
      margin-top: 50px;
    }
    .campaign-row {
      flex-wrap: wrap;

      .col-photo {
        width: 100%;
      }

      .col-content {
        width: 100%;

        img {
          height: auto;
        }
      }
    }
  }

  @media (max-width: 660px) {
    padding: 60px 0;
    .campaign-row {
      margin-bottom: 45px;
      .col-content {
        padding-top: 35px;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

}
