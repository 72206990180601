.section-steps {
  padding: 100px 0;

  .section-title {
    text-align: center;
  }

  .section-subtitle {
    text-align: center;
    padding-top: 35px;
    color: $color-grey;
  }

  .steps-grid {
    margin-top: 42px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -25px;
    margin-right: -25px;

    &__item {
      width: calc(100% / 3 - 50px);
      margin-left: 25px;
      margin-right: 25px;

      .icon {
        img {
          min-height: 72px;
          width: auto;
          display: block;
          object-fit: contain;
        }
      }

      .timeline {
        margin: 25px 0;
        position: relative;

        &:after {
          position: absolute;
          top: 50%;
          left: 0;
          display: block;
          content: "";
          width: calc(100% + 50px);
          height: 1px;
          background: #d4d4d4;
        }

        &-circle {
          width: 28px;
          height: 28px;
          border: 1px solid #cacaca;
          background-color: $color-white;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          z-index: 10;

          &:before {
            width: 16px;
            height: 16px;
            background-color: #fcb249;
            border-radius: 100%;
            display: block;
            content: "";
          }
        }
      }

      &:nth-child(3n+3) {
        .timeline {
          &:after {
            width: 100%;
          }
        }
      }

      .step-title {
        color: $color-black-lighter;

        &:after {
          display: block;
          content: "";
          width: 55px;
          height: 2px;
          background-color: #fcb249;
          margin-bottom: 19px;
          margin-top: 25px;
        }

        @media(min-width: 992px) {
          font-size: 23px;
        }
      }

      .step-description {
        color: $color-grey;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 1.88;
      }

    }
  }

  @media (max-width: 1199px) {
    padding: 90px 0;
    .steps-grid {
      margin-left: -15px;
      margin-right: -15px;
      &__item {
        width: calc(100% / 3 - 30px);
        margin-left: 15px;
        margin-right: 15px;
        .step-title {
          font-size: 19px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    padding: 80px 0;
    .steps-grid{
      margin-left: 0;
      margin-right: 0;
      &__item{
        position: relative;
        width: 100%;
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 60px;
        &:last-child{
          margin-bottom: 0;
        }
        .icon{
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 15px;
        }
        .step-title{
          text-align: center;
          &:after{
            margin-left: auto;
            margin-right: auto;
          }
        }
        .step-description{
          text-align: center;
        }
        .timeline{
          display: none;
        }
      }
    }
  }



}
