// Basic
.clearfix {
  &:before, &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.no-css {
  opacity: 0;
  visibility: hidden;
}

.css {
  opacity: 1;
  visibility: visible;
}

.wait {
  * {
    cursor: wait !important;
  }
}

.center, .aligncenter {
  text-align: center;
}

.alignright{
  text-align: right;
}
.alignleft{
  text-align: left;
}

.hidden-on-mobile {
  @media (max-width: 991px) {
    display: none;
  }
}


.btn-wrap {
  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.uppercase {
  text-transform: uppercase;
}

.white {
  color: $color-white;
}

.text-transform-none{
  text-transform: none!important;
}



.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}
