.template-wrapper {
  position: relative;
  overflow-x: hidden;
  max-width: $max-w;
}

// Admin Bar Fixes
.admin-bar {
  padding-top: 32px;
  @media (max-width: 800px) {
    #wpadminbar {
      overflow: hidden;
    }
  }
  
  .nav-main.fixed {
    top: calc(32px);
  }
  
  .nav-side {
    top: 32px;
    height: calc(100vh - 32px);
  }
  
  @media (max-width: 782px) {
    padding-top: 46px;
    .nav-main.fixed {
      top: 46px;
    }
    .nav-side {
      top: 46px;
      height: calc(100vh - 46px);
    }
  }
  @media (max-width: 600px) {
    height: 100vh;
    max-height: $max-vh;
    padding-top: 0;
    #wpadminbar {
      display: none;
    }
    .nav-side {
      top: 0;
      height: 100vh;
    }
    
    .nav-main.fixed {
      top: 0;
    }
    
  }
}

// Clients Slier
@media (min-width: 992px) {
  .page-header + .section-clients,
  .home-header + .section-clients {
    margin-top: -27.5px;
  }
}


// Page About
.page-id-8 {
  @media (min-width: 992px) {
    .section-clients {
      margin-top: -27.5px;
    }
  }
  
  .section-cases {
    background-color: rgba(#f6f6f6, .5);
  }
  
  .section-about-section {
    .col-left {
      max-width: 550px;
      
      .post-content {
        font-size: 17px;
        
        p {
          margin-bottom: 1rem;
        }
      }
    }
    
    .col-right {
      .video-wrap {
        position: relative;
        
        &:before {
          position: absolute;
          width: calc(100% + 30px);
          height: calc(100% + 30px);
          bottom: -30px;
          right: -30px;
          display: block;
          content: "";
          background-image: url(../img/triangle.png);
          background-position: left bottom;
          background-repeat: no-repeat;
          z-index: -1;
          transform: scaleX(-1);
        }
        
        &:after {
          position: absolute;
          width: calc(100% + 30px);
          height: calc(100% + 30px);
          top: -30px;
          left: -30px;
          display: block;
          content: "";
          background-image: url(../img/triangle.png);
          background-position: left bottom;
          background-repeat: no-repeat;
          z-index: -1;
          transform: scale(1, -1);
        }
      }
    }
    
    @media (max-width: 1199px) {
      .col-left {
        max-width: 100%;
      }
    }
    @media (max-width: 767px) {
      .col-right {
        margin-top: 20px;
        .video-wrap {
          background-color: $color-orange;
          padding: 15px;
          &:before {
            display: none;
          }
          
          &:after {
            display: none;
          }
        }
      }
    }
  }
  
}

// Webinars
.page-id-179 {
  @media (max-width: 1366px) {
    .page-header__bg img {
      object-position: right center;
    }
  }
}

// Proposal
.page-id-211 {
  .page-header {
    max-height: 500px;
  }
  
  .cta-section {
    .section-title {
      text-transform: unset;
    }
  }
}

// Case Studies
.page-id-259 {
  .page-header__bg img {
    @media (max-width: 1366px) {
      object-position: right center;
    }
  }
  
  .page-header__row {
    padding-top: 45px;
    @media(max-width: 991px) {
      padding-top: 0;
    }
  }
  
  .page-header {
    @media (max-width: 991px) {
      padding-bottom: 175px;
    }
  }
}


