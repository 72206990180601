.section-icon-boxes {
  padding-top: 100px;
  padding-bottom: 100px;

  .boxes-grid {
    margin-top: 70px;
    display: flex;
    flex-wrap: wrap;

    .box-item {
      width: calc(100% / 3);
      padding-left: 40px;
      padding-top: 45px;
      padding-right: 60px;
      padding-bottom: 50px;
      @include transition;

      &__icon {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        svg, img {
          max-height: 60px;
          height: 60px;
          width: auto;
          display: block;
          object-fit: contain;

          path {
            @include transition;
          }
        }
      }

      &__title {
        font-size: 24px;
        text-transform: uppercase;
        margin-bottom: 18px;
      }

      &__description {
        color: $color-grey;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
      }

      &:hover {
        box-shadow: 0 0 50px rgba(0, 0, 0, .1);

        .box-item__icon {
          svg {
            path {
              fill: $color-black;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1199px) {
    padding: 80px 0;
    .boxes-grid {
      margin-top: 60px;

      .box-item {
        width: 50%;
      }
    }
  }

  @media (max-width: 991px) {
    .boxes-grid {
      margin-top: 60px;

      .box-item {
        width: 50%;
        margin-bottom: 25px;
        padding-top: 40px;
        padding-bottom: 35px;
        padding-left: 25px;
        padding-right: 25px;

        &__title {
          font-size: 22px;
        }
      }
    }
  }

  @media (max-width: 660px) {
    padding: 60px 0;
    .boxes-grid {
      .box-item {
        max-width: 420px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
