.section-team {
  padding: 110px 0;
  background-color: #FAFAFA;

  .section-tittle {
    text-align: center;

    &:after {
      position: static;
    }
  }

  .team-grid {
    margin-top: 55px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;

    .team-member {
      position: relative;
      &__wrap {
        width: calc(100% / 5 - 40px);
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 30px;
        .name {
          margin-top: 8px;
          font-size: 18px;
          text-align: center;
          cursor: pointer;
        }
        .position {
          margin-top: 4px;
          color: #f57f20;
          font-weight: 400;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          text-align: center;
          font-size: 16px;
        }
      }
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
      border: 7px solid $color-white;
      cursor: pointer;
      @include transition;
      &:after{
        display: block;
        content: "";
        padding-bottom: 100%;
      }
      &.active {
        border-color: $color-orange;
      }
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        display: block;
        object-position: center;
        position: absolute;
      }
    }
  }

  .team-preview {
    margin-top: 12px;

    .team-member-view {
      display: none;
      align-items: center;

      &.active {
        display: flex;
      }

      .photo {
        width: 44%;
        position: relative;

        img {
          width: 100%;
          display: block;
          object-fit: contain;
        }
      }

      .info {
        padding-left: 45px;
        width: 56%;

        .name {
          @media (min-width: 1481px) {
            font-size: 36px;
          }
          text-transform: uppercase;
        }

        .position {
          margin-top: 8px;
          color: #f57f20;
          font-weight: 400;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          text-align: left;
        }

        &__inner {
          margin-top: 20px;
          padding-top: 10px;
          padding-bottom: 12px;
          padding-left: 24px;
          border-left: 4px solid #fcb249;
        }

        .info-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
        }

        .socials {
          display: flex;
          align-items: center;

          &__label {
            color: #313131;
            font-size: 17px;
            margin-right: 12px;
          }

          ul {
            display: flex;

            li {
              .socials-link {
                width: 38px;
                height: 38px;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #B6B6B6;
                margin: 0 3.5px;
                @include transition;

                svg {
                  max-width: 45%;
                  max-height: 50%;
                  height: auto;
                  object-fit: contain;

                  path {
                    fill: $color-white;
                  }
                }

                &:hover {
                  background-color: $color-orange-light;
                }
              }
            }
          }
        }

        .info-nav {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .nav-btn {
            width: 38px;
            height: 38px;
            border-radius: 100%;
            border: 2px solid #AFAFAF;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-right: 10px;

            svg {
              max-width: 9px;
              height: auto;
              display: block;
              object-fit: contain;

              path {
                @include transition;
                fill: #AFAFAF;
              }
            }

            @include transition;

            &:hover {
              border-color: #7D7D7D;

              svg {
                path {
                  fill: #7D7D7D;
                }
              }
            }
          }
        }
      }


    }
  }

  @media (max-width: 1199px) {
    padding: 80px 0;
    .team-grid {
      margin-left: -10px;
      margin-right: -10px;

      .team-member {
        &__wrap {
          width: calc(100% / 5 - 20px);
          margin-left: 10px;
          margin-right: 10px;
          margin-bottom: 20px;
        }
      }
    }
    .team-preview {
      .team-member-view {
        .info {
          padding-left: 35px;

          &__inner {
            padding-top: 0;
            padding-bottom: 0;
          }

          .description {
            font-size: 16px;
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    .container-slider {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }
    .team-grid {
      flex-wrap: nowrap;

      .team-member {
        height: 220px;

        &.active {
          border-color: $color-white;
        }

        &__wrap {
          width: 220px;
        }
      }

      .team-member__wrap {
        &.swiper-slide-active {
          .team-member {
            border-color: #ef7216;
          }
        }
      }


    }
    .team-preview {
      margin-top: 0;

      .team-member-view {
        flex-wrap: wrap;

        .photo {
          display: none;
        }

        .info {
          width: 100%;
          padding-top: 0;
          padding-left: 0;
          .description{
            text-align: center;
          }

          &__inner {
            border: 0;
            padding: 0;
          }

          .name {
            display: none;
          }

          .position {
            display: none;
          }

          .info-nav {
            display: none;
          }

          .info-footer {
            flex-wrap: wrap;
            .info-nav {
              display: none;
            }
            .socials {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 12px;

              &__label {
                margin-right: 0;
                margin-bottom: 5px;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }


}

