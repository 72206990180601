.section-content-image {
  padding: 100px 0;
  position: relative;
  &:after {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    background-color: rgba($color-black, .1);
    max-width: 1350px;
    width: 100%;
  }
  .section-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .section-title {
    margin-bottom: 20px;

    &:after {
      margin-top: 15px;
    }
  }
  .col-left {
    width: 48%;
    padding-right: 17px;
  }
  .col-right {
    width: 50%;
    padding-left: 17px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      max-width: unset;
    }
  }
  @media (max-width: 991px) {
    padding: 80px 0;
    .section-row{
      flex-wrap: wrap;
    }
    .col-left{
      padding: 0;
      width: 100%;
    }
    .col-right{
      padding: 0;
      margin-top: 35px;
      width: 100%;
      img{
        max-width: 100%;
      }
    }
  }
  @media (max-width: 767px) {
    padding: 60px 0;
  }
}
