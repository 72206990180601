.section-clients {

  .section-row {
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
  }

  .col-left {
    position: relative;
    z-index: 15;
    width: 675px;
    // width: 37.5%;
    height: 220px;
    color: $color-white;
    background-image: url(../img/clients-slider.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    align-items: center;

    &:before {
      z-index: 0;
      display: block;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 50%;
      width: 100vw;
      background-color: $color-orange
    }

    .content {
      position: relative;
      z-index: 10;
    }

    .section-title {
      &:after {
        content: "";
        display: block;
        width: 95px;
        height: 3px;
        background-color: $color-white;
        margin: 18px 0;
      }
    }
  }

  .col-right {
    z-index: 10;
    width: calc(100% - 675px + 160px + 160px);
    margin-left: -160px;
    margin-right: -160px;
    height: 165px;
    background-color: #f6f6f6;
    position: relative;
    display: flex;
    justify-content: flex-start;

    &:before {
      z-index: -1;
      display: block;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 50%;
      width: 100vw;
      background-color: #f6f6f6;
    }

    &:after {
      z-index: -1;
      display: block;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      width: 100vw;
      background-color: #f6f6f6;
    }
  }

  .clients-slider {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;

    .swiper-wrapper{
      transition-timing-function: linear!important;
    }

    .swiper-slide {
      width: 200px;
      margin: 0 40px;
      font-size: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 100%;

      img {
        height: 55px;
        width: auto;
        display: block;
        object-fit: contain;
        @include transition;
        filter: grayscale(100%);
        opacity: 0.4;

        &:hover {
          opacity: 1;
          filter: grayscale(0%);
        }
      }
    }
  }

  @media (max-width: 1680px) {
    .col-left {
      width: 535px;
      padding-right: 60px;
    }
    .col-right {
      width: calc(100% - 535px + 160px);
    }
  }
  @media (max-width: 1440px) {
    .clients-slider {
      .swiper-slide {
        width: 150px;
        margin: 0 30px;
        img {
          height: 38px;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .section-row {
      flex-direction: column;
    }
    .col-left{
      height: auto;
      width: 100%;
      padding-top: 40px;
      padding-bottom: 40px;
      padding-left: 0;
      padding-right: 0;
      background-color: $color-orange;
      .content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .section-title{
          &:after{
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
    .col-right{
      justify-content: center;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    .clients-slider{
      .swiper-slide{
        margin-left: auto;
        margin-right: auto;
        width: calc(100% / 3);
        padding-left: 35px;
        padding-right: 35px;
        img{
          height: auto;
          max-height: 50px;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
  @media (max-width: 660px) {
    .col-left{
      padding-top: 24px;
      padding-bottom: 24px;
      .section-title{
        &:after{
          margin: 12px 0;
        }
      }
    }
    .clients-slider{
      .swiper-slide{
        margin-left: 0;
        margin-right: 0;
        img{
          max-height: 45px;
        }
      }
    }
  }
  @media (max-width: 480px) {
    .clients-slider{
      .swiper-slide{
        width: calc(100% / 2);
        padding: 0 20px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          max-height: 50px;
        }
      }
    }
  }
}
