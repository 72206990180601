.section-about-section {
  padding: 100px 0;
  position: relative;

  &:after{
    border-bottom: 1px solid rgba($color-grey,.4);
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 1px;
    max-width: 1170px;
  }

  .section-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .col-left {
    width: calc(50% - 50px);
    max-width: 524px;
    .section-title{
      &:after{
        margin-top: 15px;
        position: static;
        left: 0;
        transform: none;
      }
    }
    .post-content{
      margin-top: 25px;
      p{
        margin-bottom: 2rem;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }

  .col-right {
    width: calc(50% - 50px);
    display: flex;
    justify-content: flex-end;
    .video-wrap{
      display: inline-flex;
      align-items: center;
      position: relative;
      
      .caption{
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(calc(100% + 7px));
        color: $color-orange;
        font-weight: 600;
      }
      
      .video-link {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        left: 0;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);

        &:after{
          display: block;
          content: "";
          position: absolute;
          width: 130px;
          height: 130px;
          border-radius: 100%;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          pointer-events: none;
          border: 1px solid $color-green;
          opacity: .5;
        }

        .icon {
          overflow: hidden;
          width: 112px;
          height: 112px;
          border-radius: 100%;
          box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
          border: 10px solid $color-white;
          background-color: $color-green;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          @include transition;


          svg {
            width: 15px;
            path {
              fill: $color-white;
            }
          }

          &:before {
            display: block;
            content: "";
            width: 0;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba($color-white, .4);
            @include transition;
          }

          &:hover {
            color: $color-white;

            &:before {
              width: 100%;
              background-color: rgba($color-white, .25);
            }
          }
        }

      }


    }
  }

  @media (max-width: 1199px) {
    padding: 80px 0;
    .col-left{
      width: 100%;
      max-width: 100%;
    }
    .col-right{
      margin-top: 60px;
      width: 100%;
      display: flex;
      justify-content: center;
      .video-wrap{
        .video-link{
          top: 0;
          left: 50%;
        }
      }
    }
  }
}
