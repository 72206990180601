.book-description {
  padding-top: 100px;
  padding-bottom: 100px;

  .features-grid {
    margin-top: 35px;
    max-width: 990px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;

    .feature-item {
      width: 50%;
      display: flex;
      align-items: center;
      position: relative;
      padding-bottom: 45px;
      margin-top: 45px;

      &:nth-child(odd) {
        padding-right: 60px;

        &:before {
          display: block;
          content: "";
          height: 160px;
          width: 1px;
          background-color: rgba($color-black, .15);
          position: absolute;
          top: 0;
          right: 0;
        }

        &:after {
          display: block;
          content: "";
          height: 1px;
          width: calc(100% - 60px);
          background-color: rgba($color-black, .15);
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }

      &:nth-child(even) {
        padding-left: 60px;

        &:after {
          display: block;
          content: "";
          height: 1px;
          width: calc(100% - 60px);
          background-color: rgba($color-black, .15);
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

      &:last-child,&:nth-last-child(2){
        &:after{
          display: none;
        }
      }

      &__icon {
        min-width: 150px;
        width: 150px;
        height: 150px;
        background-color: #f58020;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &:before {
          width: 165px;
          height: 165px;
          border: 2px solid #f58020;
          display: block;
          content: "";
          border-radius: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          margin: auto;
        }

        img {
          max-height: 60px;
          width: auto;
          display: block;
          object-fit: contain;
        }
      }

      &__info {
        padding-left: 25px;
      }

      &__title {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 6px;
      }

      &__list {
        display: flex;
        flex-direction: column;
        &-item {
          display: inline-flex;
          margin-bottom: 15px;
          line-height: 1.75;

          &:last-child {
            margin-bottom: 0;
          }

          .icon {
            margin-right: 8px;
            min-width: 18px;
            width: 18px;
            height: 18px;
            transform: translateY(3px);
          }

          color: $color-grey;
          font-size: 17px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
        }
      }

    }
  }

  @media (max-width: 991px) {
    padding: 80px 0;
    .features-grid {
      .feature-item {
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        margin-top: 0;

        &:nth-child(odd), &:nth-child(even) {
          padding: 40px;

          &:before {
            display: none;
          }

          &:after {
            width: 100%;
          }
        }
      }
    }
  }
  @media (max-width: 565px) {
    padding: 60px 0;
    .features-grid {
      .feature-item {
        padding: 40px 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:last-child{
          &:after{
            display: none;
          }
        }
        &__info{
          margin-top: 30px;
          padding-left: 0;
        }
        &__icon{
          width: 140px;
          height: 140px;
          min-width: 140px;
        }
      }
    }
  }
}

