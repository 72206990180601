@font-face {
  font-family: 'GT Walsheim Pro';
  src: url("../fonts/GTWalsheimPro-Light.eot");
  src: local("GT Walsheim Pro Light"), local("GTWalsheimPro-Light"), url("../fonts/GTWalsheimPro-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-Light.woff2") format("woff2"), url("../fonts/GTWalsheimPro-Light.woff") format("woff"), url("../fonts/GTWalsheimPro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'GT Walsheim Pro';
  src: url("../fonts/GTWalsheimPro-Regular.eot");
  src: local("GT Walsheim Pro Regular"), local("GTWalsheimPro-Regular"), url("../fonts/GTWalsheimPro-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-Regular.woff2") format("woff2"), url("../fonts/GTWalsheimPro-Regular.woff") format("woff"), url("../fonts/GTWalsheimPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'GT Walsheim Pro';
  src: url("../fonts/GTWalsheimPro-Thin.eot");
  src: local("GT Walsheim Pro Thin"), local("GTWalsheimPro-Thin"), url("../fonts/GTWalsheimPro-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-Thin.woff2") format("woff2"), url("../fonts/GTWalsheimPro-Thin.woff") format("woff"), url("../fonts/GTWalsheimPro-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'GT Walsheim Pro';
  src: url("../fonts/GTWalsheimPro-Bold.eot");
  src: local("GT Walsheim Pro Bold"), local("GTWalsheimPro-Bold"), url("../fonts/GTWalsheimPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-Bold.woff2") format("woff2"), url("../fonts/GTWalsheimPro-Bold.woff") format("woff"), url("../fonts/GTWalsheimPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'GT Walsheim Pro';
  src: url("../fonts/GTWalsheimPro-Medium.eot");
  src: local("GT Walsheim Pro Medium"), local("GTWalsheimPro-Medium"), url("../fonts/GTWalsheimPro-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-Medium.woff2") format("woff2"), url("../fonts/GTWalsheimPro-Medium.woff") format("woff"), url("../fonts/GTWalsheimPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'GT Walsheim Pro';
  src: url("../fonts/GTWalsheimPro-Black.eot");
  src: local("GT Walsheim Pro Black"), local("GTWalsheimPro-Black"), url("../fonts/GTWalsheimPro-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/GTWalsheimPro-Black.woff2") format("woff2"), url("../fonts/GTWalsheimPro-Black.woff") format("woff"), url("../fonts/GTWalsheimPro-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  overflow-anchor: none; }
  *::after, *::before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    outline: 0; }
  *:active, *:focus, *:hover, *:visited {
    outline: 0;
    -webkit-tap-highlight-color: transparent; }

/* Webkit autofill background fix */
html body input:-webkit-autofill, html body input:-webkit-autofill:hover, html body input:-webkit-autofill:focus, html body input:-webkit-autofill:active, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, textarea:-webkit-autofill:active {
  transition: background-color 500000s ease-in-out 0s !important; }

button, input, hr, optgroup, select, textarea, p, h1, h2, h3, h4, h5, h6 {
  margin: 0; }

progress, sub, sup {
  vertical-align: baseline; }

pre, code {
  overflow: auto; }

embed, iframe, img, legend, object, pre, details {
  max-width: 100%;
  display: block;
  border: 0;
  outline: 0; }

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

a {
  background-color: transparent;
  text-decoration: unset;
  color: unset; }
  a:active, a:focus, a:hover, a:visited {
    color: unset;
    text-decoration: unset; }

b, strong {
  font-weight: bold; }

code, kbd, samp, pre {
  font-family: monospace, monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

img {
  border: none;
  height: auto; }

button, select, input, textarea, label {
  text-transform: unset;
  border: none;
  outline: none;
  background: unset;
  appearance: none; }

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button; }

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0; }

[type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
  outline: ButtonText dotted 1px; }

textarea {
  width: 100%;
  display: block;
  resize: vertical; }

[type=checkbox], [type=radio] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto; }

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

.hide, .hidden, [hidden] {
  display: none; }

body, button, input, optgroup, select, textarea {
  color: #404040;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1.5; }

select::-ms-expand {
  display: none; }

input {
  border-radius: 0;
  box-shadow: none; }

cite, dfn, em, i {
  font-style: italic; }

blockquote {
  margin: 0 1.5em; }

address {
  margin: 0 0 1.5em; }

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: .9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  padding: 1.6em;
  color: #000; }

code, kbd, tt, var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: .9375rem; }

abbr, acronym {
  border-bottom: 1px dotted #666;
  cursor: help; }

ins, mark {
  background: #fff9c0;
  text-decoration: none; }

big {
  font-size: 125%; }

ol, ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

ol {
  list-style-type: decimal; }

li > ol, li > ul {
  margin: 0;
  padding: 0; }

dt {
  font-weight: 700; }

dd {
  margin: 0 1.5em 1.5em; }

figure {
  margin: 0; }

table {
  margin: 0;
  width: auto; }

input[type=color]:focus, input[type=date]:focus, input[type=datetime-local]:focus, input[type=datetime]:focus, input[type=email]:focus, input[type=month]:focus, input[type=number]:focus, input[type=password]:focus, input[type=range]:focus, input[type=search]:focus, input[type=tel]:focus, input[type=text]:focus, input[type=time]:focus, input[type=url]:focus, input[type=week]:focus, textarea:focus {
  color: inherit; }

input {
  appearance: none; }
  input[type=search] {
    appearance: none;
    outline: none; }
  input::-webkit-search-cancel-button {
    display: none;
    pointer-events: none;
    opacity: 0;
    visibility: hidden; }

body {
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 18px;
  line-height: 1.7;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal; }
  body p {
    font-size: inherit;
    font-family: inherit;
    line-height: inherit; }
  @media (max-width: 1199px) {
    body {
      font-size: 17px; } }
  @media (max-width: 991px) {
    body {
      font-size: 16px; } }

.font-large {
  font-size: 20px; }
  @media (max-width: 768px) {
    .font-large {
      font-size: 18px; } }

.font-extra-large {
  font-size: 22px; }
  @media (max-width: 1199px) {
    .font-extra-large {
      font-size: 20px; } }
  @media (max-width: 768px) {
    .font-extra-large {
      font-size: 18px; } }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: bold;
  line-height: 1.25;
  color: #000; }

h1, .h1 {
  font-size: 60px; }
  @media (max-width: 1480px) {
    h1, .h1 {
      font-size: 50px; } }
  @media (max-width: 1199px) {
    h1, .h1 {
      font-size: 46px; } }
  @media (max-width: 991px) {
    h1, .h1 {
      font-size: 42px; } }
  @media (max-width: 768px) {
    h1, .h1 {
      font-size: 38px; } }
  @media (max-width: 480px) {
    h1, .h1 {
      font-size: 32px; } }

h2, .h2 {
  font-size: 45px; }
  @media (max-width: 1480px) {
    h2, .h2 {
      font-size: 40px; } }
  @media (max-width: 1199px) {
    h2, .h2 {
      font-size: 38px; } }
  @media (max-width: 991px) {
    h2, .h2 {
      font-size: 36px; } }
  @media (max-width: 768px) {
    h2, .h2 {
      font-size: 32px; } }
  @media (max-width: 480px) {
    h2, .h2 {
      font-size: 28px; } }

h3, .h3 {
  font-size: 40px; }
  @media (max-width: 1480px) {
    h3, .h3 {
      font-size: 34px; } }
  @media (max-width: 1199px) {
    h3, .h3 {
      font-size: 30px; } }
  @media (max-width: 991px) {
    h3, .h3 {
      font-size: 28px; } }
  @media (max-width: 768px) {
    h3, .h3 {
      font-size: 26px; } }
  @media (max-width: 480px) {
    h3, .h3 {
      font-size: 24px; } }

h4, .h4 {
  font-size: 33px; }
  @media (max-width: 1480px) {
    h4, .h4 {
      font-size: 30px; } }
  @media (max-width: 1199px) {
    h4, .h4 {
      font-size: 28px; } }
  @media (max-width: 991px) {
    h4, .h4 {
      font-size: 26px; } }
  @media (max-width: 768px) {
    h4, .h4 {
      font-size: 24px; } }
  @media (max-width: 480px) {
    h4, .h4 {
      font-size: 20px; } }

h5, .h5 {
  font-size: 25px; }
  @media (max-width: 1199px) {
    h5, .h5 {
      font-size: 24px; } }
  @media (max-width: 991px) {
    h5, .h5 {
      font-size: 22px; } }
  @media (max-width: 768px) {
    h5, .h5 {
      font-size: 20px; } }
  @media (max-width: 480px) {
    h5, .h5 {
      font-size: 18px; } }

h6, .h6 {
  font-size: 20px; }
  @media (max-width: 480px) {
    h6, .h6 {
      font-size: 18px; } }

.sub-heading {
  color: #fcb249;
  font-size: 23px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3.22px; }
  @media (max-width: 1480px) {
    .sub-heading {
      font-size: 22px; } }
  @media (max-width: 1199px) {
    .sub-heading {
      font-size: 20px; } }
  @media (max-width: 768px) {
    .sub-heading {
      font-size: 18px; } }

.section-title {
  color: #000; }

.title-line {
  position: relative; }
  .title-line:after {
    display: block;
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 75px;
    height: 3px;
    bottom: -15px;
    background-color: #ef7216; }
  .title-line.line-left:after {
    position: static;
    left: 0;
    transform: none; }

.post-content {
  color: #000; }
  .post-content img {
    display: inline-block;
    max-width: 100%; }
  .post-content p {
    margin-bottom: 1rem; }
    .post-content p:last-child {
      margin-bottom: 0; }
  .post-content h1, .post-content h2, .post-content h3, .post-content h4, .post-content h5, .post-content h6,
  .post-content .h1, .post-content .h2, .post-content .h3, .post-content .h4, .post-content .h5, .post-content .h6 {
    margin-top: 40px;
    text-transform: uppercase; }
    .post-content h1:after, .post-content h2:after, .post-content h3:after, .post-content h4:after, .post-content h5:after, .post-content h6:after,
    .post-content .h1:after, .post-content .h2:after, .post-content .h3:after, .post-content .h4:after, .post-content .h5:after, .post-content .h6:after {
      display: block;
      content: "";
      position: static;
      width: 75px;
      height: 3px;
      background-color: #ef7216;
      margin-top: 15px;
      margin-bottom: 15px; }
  .post-content hr {
    display: block;
    border: 0;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.15); }
  .post-content strong {
    color: #000; }
  .post-content a {
    color: #ef7216;
    transition: 0.3s all ease; }
    .post-content a:hover {
      color: #fcb249; }
  .post-content ul li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px; }
    .post-content ul li:before {
      display: block;
      content: "";
      min-width: 18px;
      width: 18px;
      height: 18px;
      transform: translateY(7px);
      background-image: url(../img/check.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      left: 0; }
      @media (max-width: 991px) {
        .post-content ul li:before {
          transform: translateY(3px); } }
  .post-content .list-wrap {
    display: flex;
    flex-wrap: wrap; }
    .post-content .list-wrap ul, .post-content .list-wrap ol {
      width: calc(100% / 3); }
      @media (max-width: 1199px) {
        .post-content .list-wrap ul, .post-content .list-wrap ol {
          width: calc(100% / 2); } }
      @media (max-width: 767px) {
        .post-content .list-wrap ul, .post-content .list-wrap ol {
          width: 100%; } }
  .post-content ol {
    padding-left: 22px; }
  .post-content img {
    margin: 2rem 0; }
  .post-content .wp-block-image.wide img {
    width: 100%;
    display: block; }
  .post-content .wp-block-embed__wrapper {
    overflow: hidden;
    padding-top: 56.25%;
    /* 16:9 */
    position: relative; }
    .post-content .wp-block-embed__wrapper .rll-youtube-player {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      height: 100%;
      width: 100%; }
    .post-content .wp-block-embed__wrapper iframe {
      position: absolute;
      top: 0;
      left: 0;
      border: 0;
      width: 100%;
      height: 100%; }

/* Old Browser */
.browsers__placeholder {
  font-family: "Helvetica", sans-serif;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  min-width: 100vw;
  background-image: none !important;
  text-align: center;
  background-color: black !important;
  font-size: 20px;
  color: #fff;
  z-index: 100000000000000000000000000000000000;
  line-height: 1.6; }
  .browsers__placeholder p {
    color: #fff;
    font-size: 20px;
    text-align: center;
    line-height: 1.6;
    font-family: inherit; }
  .browsers__placeholder a {
    color: red; }

.transition {
  transition: 0.3s all ease; }

.clearfix:before, .clearfix:after {
  content: "";
  display: table;
  clear: both; }

.no-css {
  opacity: 0;
  visibility: hidden; }

.css {
  opacity: 1;
  visibility: visible; }

.wait * {
  cursor: wait !important; }

.center, .aligncenter {
  text-align: center; }

.alignright {
  text-align: right; }

.alignleft {
  text-align: left; }

@media (max-width: 991px) {
  .hidden-on-mobile {
    display: none; } }

.btn-wrap.center {
  display: flex;
  justify-content: center;
  align-items: center; }

.uppercase {
  text-transform: uppercase; }

.white {
  color: #fff; }

.text-transform-none {
  text-transform: none !important; }

.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out; }

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8; }

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0; }

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out; }

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1; }

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto; }

@media (max-width: 575px) {
  .container {
    width: 100%;
    max-width: 100%; } }

@media (min-width: 576px) {
  .container {
    width: 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%; } }

@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%; } }

@media (min-width: 1200px) {
  .container {
    width: 1200px;
    max-width: 100%; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.hidden-xs-up {
  display: none !important; }

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important; } }

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important; } }

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important; } }

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important; } }

@media (max-width: 991px) {
  html .hidden-md-down {
    display: none !important; } }

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important; } }

@media (max-width: 1199px) {
  html .hidden-lg-down {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important; } }

.hidden-xl-down {
  display: none !important; }

.nav-main {
  max-width: calc(100vw - var(--scroll-width));
  position: absolute;
  top: 38px;
  left: 0;
  right: 0;
  height: 80px;
  z-index: 150; }
  .nav-main__inner {
    width: 100%;
    height: 100%; }
    .nav-main__inner .container {
      height: 100%;
      position: relative; }
  .nav-main__row {
    height: 100%;
    display: flex;
    justify-content: space-between; }
  .nav-main__left {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .nav-main__right {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .nav-main__logo img {
    max-height: 46px;
    width: auto;
    display: block;
    object-fit: contain; }
  .nav-main__toggle {
    display: none; }
  .nav-main .menu {
    display: flex;
    justify-content: flex-end;
    height: 100%; }
    .nav-main .menu > li {
      margin-left: 32px;
      padding: 10px 0;
      height: 100%;
      display: flex;
      align-items: center; }
      .nav-main .menu > li:last-child {
        margin-right: 32px; }
      .nav-main .menu > li a {
        color: #fff;
        font-family: "GT Walsheim Pro", sans-serif;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        text-transform: uppercase;
        position: relative;
        transition: 0.3s all ease; }
        .nav-main .menu > li a:before {
          position: absolute;
          display: block;
          content: "";
          bottom: -2px;
          width: 0;
          height: 1px;
          transition: width 0.55s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
          right: 0;
          background-color: #ef7216; }
        .nav-main .menu > li a:hover {
          color: #ef7216; }
          .nav-main .menu > li a:hover:before {
            width: 100%;
            left: 0; }
      .nav-main .menu > li.current_page_item a {
        color: #ef7216; }
        .nav-main .menu > li.current_page_item a > .nav-main .menu > li.current_page_item a:before {
          width: 100%;
          left: 0; }
      .nav-main .menu > li.menu-item-has-children {
        position: relative; }
        .nav-main .menu > li.menu-item-has-children .sub-menu {
          padding: 25px 10px;
          width: 240px;
          position: absolute;
          top: 100%;
          box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
          background-color: #fff;
          transition: 0.3s all ease;
          opacity: 0;
          pointer-events: none;
          transform: translateY(15px) translateX(-30px); }
          .nav-main .menu > li.menu-item-has-children .sub-menu li {
            margin: 0;
            padding-left: 14px;
            display: flex;
            position: relative;
            margin-bottom: 18px; }
            .nav-main .menu > li.menu-item-has-children .sub-menu li:last-child {
              margin-bottom: 0; }
            .nav-main .menu > li.menu-item-has-children .sub-menu li:before {
              content: "";
              min-width: 5px;
              height: 5px;
              border-radius: 100%;
              display: block;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%); }
            .nav-main .menu > li.menu-item-has-children .sub-menu li a {
              text-transform: uppercase;
              font-weight: 400;
              transition: 0.3s all ease;
              font-family: "GT Walsheim Pro", sans-serif;
              font-size: 16px;
              color: #000;
              line-height: 1.2em; }
              .nav-main .menu > li.menu-item-has-children .sub-menu li a:hover {
                color: #ef7216; }
            .nav-main .menu > li.menu-item-has-children .sub-menu li.current_page_item > a {
              color: #ef7216; }
          .nav-main .menu > li.menu-item-has-children .sub-menu:hover {
            opacity: 1;
            pointer-events: all;
            transform: translateY(0) translateX(-30px); }
        .nav-main .menu > li.menu-item-has-children.active > .sub-menu {
          opacity: 1;
          pointer-events: all;
          transform: translateY(0) translateX(-30px); }
      .nav-main .menu > li.mega-menu-wrapper {
        position: static; }
        .nav-main .menu > li.mega-menu-wrapper > .sub-menu {
          padding: 10px 25px;
          width: 100%;
          left: 0;
          right: 0;
          position: absolute;
          top: 100%;
          box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
          background-color: #fff;
          display: flex;
          transition: 0.3s all ease;
          opacity: 0;
          pointer-events: none;
          transform: translateY(15px) translateX(0); }
          .nav-main .menu > li.mega-menu-wrapper > .sub-menu li {
            margin: 0;
            padding-left: 14px;
            display: block;
            position: relative;
            margin-bottom: 18px; }
            .nav-main .menu > li.mega-menu-wrapper > .sub-menu li:last-child {
              margin-bottom: 0; }
            .nav-main .menu > li.mega-menu-wrapper > .sub-menu li:before {
              content: "";
              min-width: 5px;
              height: 5px;
              border-radius: 100%;
              display: block;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%); }
            .nav-main .menu > li.mega-menu-wrapper > .sub-menu li a {
              text-transform: uppercase;
              font-weight: 400;
              transition: 0.3s all ease;
              font-family: "GT Walsheim Pro", sans-serif;
              font-size: 16px;
              color: #000;
              line-height: 1.2em; }
              .nav-main .menu > li.mega-menu-wrapper > .sub-menu li a:hover {
                color: #ef7216; }
            .nav-main .menu > li.mega-menu-wrapper > .sub-menu li.current_page_item > a {
              color: #ef7216; }
          .nav-main .menu > li.mega-menu-wrapper > .sub-menu .sub-menu {
            position: static;
            opacity: 1;
            pointer-events: none; }
          .nav-main .menu > li.mega-menu-wrapper > .sub-menu > li {
            margin: 0;
            padding: 15px;
            flex: 1; }
            .nav-main .menu > li.mega-menu-wrapper > .sub-menu > li > a {
              font-weight: bold;
              margin-bottom: 18px;
              display: inline-block; }
              .nav-main .menu > li.mega-menu-wrapper > .sub-menu > li > a:before {
                display: none; }
              .nav-main .menu > li.mega-menu-wrapper > .sub-menu > li > a[href='#'] {
                cursor: default; }
                .nav-main .menu > li.mega-menu-wrapper > .sub-menu > li > a[href='#']:hover {
                  color: #000; }
            .nav-main .menu > li.mega-menu-wrapper > .sub-menu > li .sub-menu li {
              padding: 0; }
          .nav-main .menu > li.mega-menu-wrapper > .sub-menu:hover {
            opacity: 1;
            pointer-events: all;
            transform: translateY(0) translateX(0); }
        .nav-main .menu > li.mega-menu-wrapper.active > .sub-menu {
          opacity: 1;
          pointer-events: all;
          transform: translateY(0) translateX(0); }
          .nav-main .menu > li.mega-menu-wrapper.active > .sub-menu .sub-menu {
            pointer-events: all; }
  .nav-main .btn-proposal {
    margin-left: 15px; }
  .nav-main.fixed {
    z-index: 200;
    position: fixed;
    width: 100%;
    max-width: calc(100vw - var(--scroll-width));
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 1px 15px rgba(115, 122, 142, 0.15);
    transition: 0.3s all ease;
    transform: translateY(-110%); }
    .nav-main.fixed.active {
      transform: translateY(0); }
    .nav-main.fixed .nav-main__logo img {
      max-height: 46px; }
    .nav-main.fixed .menu li a {
      color: #000; }
      .nav-main.fixed .menu li a:hover {
        color: #ef7216; }
    .nav-main.fixed .menu li.current_page_item a {
      color: #ef7216; }
  @media (max-width: 1440px) {
    .nav-main {
      height: 80px;
      top: 20px; }
      .nav-main__logo img {
        max-height: 50px; } }
  @media (max-width: 1366px) {
    .nav-main__row {
      margin-left: auto;
      margin-right: auto; }
    .nav-main .menu li {
      margin-left: 25px; }
      .nav-main .menu li a {
        font-size: 15px; } }
  @media (max-width: 1199px) {
    .nav-main .btn-proposal {
      display: none; } }
  @media (max-width: 991px) {
    .nav-main {
      height: 60px;
      top: 15px; }
      .nav-main__logo img {
        max-height: 40px; }
      .nav-main .menu {
        display: none; }
      .nav-main__toggle {
        display: block; }
        .nav-main__toggle span {
          display: block;
          margin: 6px 0;
          width: 30px;
          height: 2px;
          background-color: #fff; }
      .nav-main.fixed {
        height: 60px; }
        .nav-main.fixed .nav-main__logo img {
          max-height: 40px; }
        .nav-main.fixed .nav-main__toggle span {
          background-color: #000; } }

.nav-side {
  max-width: calc(100vw - var(--scroll-width));
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  transition: 0.3s all ease;
  z-index: 250;
  pointer-events: none;
  transform: translateX(102%);
  background-color: #1F2934; }
  .nav-side.active {
    pointer-events: all;
    transform: translateX(0); }
  .nav-side__header {
    border-bottom: 1px solid #d0d0d0;
    display: flex;
    align-items: center; }
    .nav-side__header .container {
      height: 65px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
  .nav-side__logo img {
    max-height: 45px;
    width: auto;
    display: block;
    object-fit: contain; }
  .nav-side__scroller {
    padding-top: 40px;
    padding-bottom: 150px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    max-height: 100%; }
  .nav-side__close span {
    display: block;
    margin: 6px 0;
    width: 30px;
    height: 2px;
    background-color: #fff; }
    .nav-side__close span:nth-child(1) {
      transform: translateY(4px) rotate(45deg); }
    .nav-side__close span:nth-child(2) {
      transform: translateY(-4px) rotate(-45deg); }
  .nav-side .menu {
    margin-bottom: 25px; }
    .nav-side .menu li {
      display: block;
      text-align: center;
      margin-top: 35px; }
      .nav-side .menu li a {
        display: inline-block;
        color: #fff;
        font-family: "GT Walsheim Pro", sans-serif;
        font-size: 20px;
        line-height: 1;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        text-transform: uppercase;
        position: relative;
        text-align: center;
        transition: 0.3s all ease; }
        .nav-side .menu li a span:not(.sub-menu-toggle) {
          position: relative; }
          .nav-side .menu li a span:not(.sub-menu-toggle):before {
            position: absolute;
            display: block;
            content: "";
            bottom: -2px;
            width: 0;
            height: 1px;
            transition: width 0.55s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
            right: 0;
            background-color: #ef7216; }
        .nav-side .menu li a:hover {
          color: #ef7216; }
          .nav-side .menu li a:hover span:not(.sub-menu-toggle):before {
            width: 100%;
            left: 0; }
      .nav-side .menu li.current_page_item > a {
        color: #ef7216; }
      .nav-side .menu li.menu-item-has-children, .nav-side .menu li.mega-menu-wrapper {
        transform: translateX(15px); }
        .nav-side .menu li.menu-item-has-children > a, .nav-side .menu li.mega-menu-wrapper > a {
          display: inline-flex;
          justify-content: space-between;
          align-items: center; }
          .nav-side .menu li.menu-item-has-children > a .sub-menu-toggle, .nav-side .menu li.mega-menu-wrapper > a .sub-menu-toggle {
            margin-left: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateY(-2px); }
            .nav-side .menu li.menu-item-has-children > a .sub-menu-toggle svg, .nav-side .menu li.mega-menu-wrapper > a .sub-menu-toggle svg {
              height: 15px;
              width: auto;
              display: block;
              object-fit: contain; }
              .nav-side .menu li.menu-item-has-children > a .sub-menu-toggle svg path, .nav-side .menu li.menu-item-has-children > a .sub-menu-toggle svg polygon, .nav-side .menu li.mega-menu-wrapper > a .sub-menu-toggle svg path, .nav-side .menu li.mega-menu-wrapper > a .sub-menu-toggle svg polygon {
                fill: #fff; }
        .nav-side .menu li.menu-item-has-children .sub-menu, .nav-side .menu li.mega-menu-wrapper .sub-menu {
          display: none;
          transform: translateX(-15px); }
          .nav-side .menu li.menu-item-has-children .sub-menu li, .nav-side .menu li.mega-menu-wrapper .sub-menu li {
            margin-top: 25px; }
            .nav-side .menu li.menu-item-has-children .sub-menu li a, .nav-side .menu li.mega-menu-wrapper .sub-menu li a {
              font-size: 95%; }
            .nav-side .menu li.menu-item-has-children .sub-menu li.menu-item-has-children > a, .nav-side .menu li.mega-menu-wrapper .sub-menu li.menu-item-has-children > a {
              font-size: 20px; }
      .nav-side .menu li.active > a > .sub-menu-toggle {
        transform: rotate(180deg); }
  .nav-side .btn-wrap {
    margin-top: 40px; }
    .nav-side .btn-wrap .btn-proposal {
      width: 85%; }
  @media (max-width: 660px) {
    .nav-side__header .container {
      height: 60px; }
    .nav-side__logo img {
      max-height: 38px; } }

.btn {
  min-width: 235px;
  height: 66px;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #fff;
  font-family: "GT Walsheim Pro", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 2.16px;
  padding-left: 15px;
  padding-right: 15px;
  transition: 0.3s all ease;
  position: relative;
  cursor: pointer; }
  .btn.btn-medium {
    min-width: 335px; }
  .btn.btn-small {
    min-width: 195px; }
  .btn.btn-size-medium {
    font-size: 17px;
    height: 62px; }
  .btn.btn-tiny {
    font-size: 14px;
    min-width: 160px;
    height: 54px; }
    @media (max-width: 1199px) {
      .btn.btn-tiny {
        font-size: 12px;
        min-width: 148px;
        height: 48px; } }
  @media (max-width: 1440px) {
    .btn {
      font-size: 16px;
      height: 58px;
      min-width: 215px; } }
  @media (max-width: 991px) {
    .btn {
      font-size: 15px;
      height: 54px;
      min-width: 200px; } }
  @media (max-width: 345px) {
    .btn {
      font-size: 14px;
      height: 48px;
      min-width: 180px; } }
  .btn.btn-green {
    background-color: #6cad4e; }
    .btn.btn-green:before {
      display: block;
      content: "";
      width: 4px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.4);
      transition: 0.3s all ease; }
    .btn.btn-green:hover {
      color: #fff; }
      .btn.btn-green:hover:before {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.25); }
    .btn.btn-green:active {
      color: #fff; }
    .btn.btn-green:focus {
      color: #fff; }
    .btn.btn-green:visited {
      color: #fff; }
  .btn.btn-orange {
    background-color: #ef7216; }
    .btn.btn-orange:before {
      display: block;
      content: "";
      width: 4px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.4);
      transition: 0.3s all ease; }
    .btn.btn-orange:hover {
      color: #fff; }
      .btn.btn-orange:hover:before {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.25); }
    .btn.btn-orange:active {
      color: #fff; }
    .btn.btn-orange:focus {
      color: #fff; }
    .btn.btn-orange:visited {
      color: #fff; }
  .btn.btn-orange-2 {
    background-color: #fcb249; }
    .btn.btn-orange-2:before {
      display: block;
      content: "";
      width: 4px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.4);
      transition: 0.3s all ease; }
    .btn.btn-orange-2:hover {
      color: #fff; }
      .btn.btn-orange-2:hover:before {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.25); }
    .btn.btn-orange-2:active {
      color: #fff; }
    .btn.btn-orange-2:focus {
      color: #fff; }
    .btn.btn-orange-2:visited {
      color: #fff; }

.swal2-styled.swal2-confirm {
  display: inline-flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #fff;
  font-family: "GT Walsheim Pro", sans-serif;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 2.16px;
  padding-left: 15px;
  padding-right: 15px;
  transition: 0.3s all ease;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  min-width: 160px;
  height: 54px;
  background-color: #ef7216; }
  @media (max-width: 1199px) {
    .swal2-styled.swal2-confirm {
      font-size: 12px;
      min-width: 148px;
      height: 48px; } }
  .swal2-styled.swal2-confirm:before {
    display: block;
    content: "";
    width: 4px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.4);
    transition: 0.3s all ease; }
  .swal2-styled.swal2-confirm:hover {
    color: #fff; }
    .swal2-styled.swal2-confirm:hover:before {
      width: 100%;
      background-color: rgba(255, 255, 255, 0.25); }
  .swal2-styled.swal2-confirm:active {
    color: #fff; }
  .swal2-styled.swal2-confirm:focus {
    color: #fff; }
  .swal2-styled.swal2-confirm:visited {
    color: #fff; }

.footer {
  border-top: 1px solid #fff;
  background-color: #222222;
  padding-top: 100px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px; }
  .footer a {
    transition: 0.3s all ease;
    position: relative; }
    .footer a:before {
      position: absolute;
      display: block;
      content: "";
      bottom: -2px;
      width: 0;
      height: 1px;
      transition: width 0.55s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      right: 0;
      background-color: #ef7216; }
    .footer a:hover {
      color: #ef7216; }
      .footer a:hover:before {
        width: 100%;
        left: 0; }
  .footer__bot {
    padding: 30px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.25); }
    .footer__bot-links {
      display: flex; }
      .footer__bot-links li {
        margin-right: 24px; }
        .footer__bot-links li:empty {
          display: none; }
    .footer__bot-row {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .footer__bot .col-left {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: center; }
    .footer__bot .col-right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
  .footer__top {
    padding-bottom: 10px; }
    .footer__top-row {
      display: flex;
      justify-content: space-between;
      margin-left: -25px;
      margin-right: -25px; }
    .footer__top .footer-sidebar {
      margin-left: 25px;
      margin-right: 25px;
      margin-bottom: 20px; }
      .footer__top .footer-sidebar__title {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: bold;
        color: #fff;
        letter-spacing: 0.8px;
        line-height: 1;
        margin-bottom: 38px; }
      .footer__top .footer-sidebar--awards {
        max-width: 175px; }
        .footer__top .footer-sidebar--awards .awards-list .award {
          display: block;
          margin-bottom: 10px; }
          .footer__top .footer-sidebar--awards .awards-list .award:before {
            display: none; }
      .footer__top .footer-sidebar--links {
        margin-left: 50px; }
        .footer__top .footer-sidebar--links .quick-link {
          margin-top: -7px;
          display: flex; }
          .footer__top .footer-sidebar--links .quick-link__col {
            display: block;
            margin-right: 42px; }
            .footer__top .footer-sidebar--links .quick-link__col li {
              margin-bottom: 10px; }
              .footer__top .footer-sidebar--links .quick-link__col li a {
                white-space: nowrap; }
      .footer__top .footer-sidebar--contact {
        max-width: 200px; }
        .footer__top .footer-sidebar--contact p {
          margin-bottom: 12px; }
      .footer__top .footer-sidebar--description {
        max-width: 370px;
        display: flex;
        flex-direction: column;
        align-items: flex-end; }
        .footer__top .footer-sidebar--description .logo {
          display: inline-block;
          margin-bottom: 16px;
          margin-top: -18px; }
          .footer__top .footer-sidebar--description .logo:before {
            display: none; }
          .footer__top .footer-sidebar--description .logo img {
            max-width: 210px;
            height: auto;
            display: block;
            object-fit: contain; }
        .footer__top .footer-sidebar--description .footer-description {
          color: rgba(255, 255, 255, 0.6);
          font-size: 16px;
          text-align: right; }
        .footer__top .footer-sidebar--description .socials {
          margin-top: 35px;
          display: flex;
          justify-content: flex-end;
          align-items: center; }
          .footer__top .footer-sidebar--description .socials-link {
            margin-left: 35px;
            display: flex;
            justify-content: center;
            align-items: center; }
            .footer__top .footer-sidebar--description .socials-link:before {
              display: none; }
            .footer__top .footer-sidebar--description .socials-link svg {
              height: 20px;
              width: auto;
              display: block;
              object-fit: contain; }
  @media (max-width: 1199px) {
    .footer {
      padding-top: 75px; }
      .footer__top .footer-sidebar__title {
        font-size: 18px; }
      .footer__top .footer-sidebar--awards {
        max-width: 165px; }
      .footer__top .footer-sidebar--links {
        margin-left: 25px; }
        .footer__top .footer-sidebar--links .quick-link {
          flex-wrap: wrap; }
          .footer__top .footer-sidebar--links .quick-link__col {
            margin-right: 0; }
      .footer__top .footer-sidebar--contact {
        max-width: 180px; }
      .footer__top .footer-sidebar--description {
        max-width: 280px; }
        .footer__top .footer-sidebar--description .footer-description {
          font-size: 15px; }
        .footer__top .footer-sidebar--description .socials {
          margin-top: 25px; } }
  @media (max-width: 991px) {
    .footer__top {
      padding-bottom: 30px; }
      .footer__top-row {
        flex-wrap: wrap;
        justify-content: center; }
      .footer__top .footer-sidebar {
        flex: 1;
        max-width: 100%;
        min-width: calc(100% / 3 - 50px);
        margin-bottom: 45px; }
        .footer__top .footer-sidebar--links .quick-link__col {
          margin-right: 25px; }
          .footer__top .footer-sidebar--links .quick-link__col:last-child {
            margin-right: 0; }
        .footer__top .footer-sidebar--description {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .footer__top .footer-sidebar--description .logo {
            margin-top: 0; }
          .footer__top .footer-sidebar--description .footer-description {
            text-align: center;
            max-width: 320px;
            margin-left: auto;
            margin-right: auto; }
          .footer__top .footer-sidebar--description .socials {
            justify-content: center; }
            .footer__top .footer-sidebar--description .socials a {
              margin-left: 16px;
              margin-right: 16px; }
    .footer__bot-links li {
      margin: 0 12px; }
      .footer__bot-links li:first-child {
        margin-left: 0; }
      .footer__bot-links li:last-child {
        margin-right: 0; }
    .footer__bot-row {
      flex-direction: column; }
    .footer__bot .col-left {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center; }
    .footer__bot .col-right {
      margin-top: 15px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center; } }
  @media (max-width: 767px) {
    .footer {
      padding-top: 60px; }
      .footer__top .footer-sidebar {
        width: 100%;
        flex: unset; }
        .footer__top .footer-sidebar--awards {
          max-width: 175px; }
        .footer__top .footer-sidebar--links {
          max-width: 200px; }
        .footer__top .footer-sidebar--contact {
          max-width: 280px; } }
  @media (max-width: 480px) {
    .footer__top .footer-sidebar--awards {
      max-width: 320px; }
      .footer__top .footer-sidebar--awards .awards-list {
        max-width: 175px; }
    .footer__top .footer-sidebar--links {
      max-width: 320px; }
      .footer__top .footer-sidebar--links .quick-link__col {
        margin-right: 45px; }
    .footer__top .footer-sidebar--contact {
      max-width: 320px; } }
  @media (max-width: 375px) {
    .footer__bot .col-right {
      margin-top: 25px;
      max-width: 225px;
      margin-left: auto;
      margin-right: auto; } }

.form__group {
  margin-bottom: 20px; }
  @media (max-width: 1480px) {
    .form__group {
      margin-bottom: 16px; } }

.form .input {
  width: 100%;
  height: 54px;
  background-color: #f7f7f7;
  padding-left: 25px;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  font-family: "GT Walsheim Pro", sans-serif; }
  .form .input::placeholder {
    color: #898989;
    font-family: "GT Walsheim Pro", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left; }
  @media (max-width: 1480px) {
    .form .input {
      font-size: 15px;
      height: 50px; }
      .form .input::placeholder {
        font-size: 15px; } }

.form .input-border {
  background-color: transparent;
  border: 1px solid #e7e7e7; }

.form .textarea {
  width: 100%;
  min-height: 180px;
  max-height: 450px;
  resize: vertical;
  background-color: #f7f7f7;
  padding: 25px;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  font-family: "GT Walsheim Pro", sans-serif; }
  .form .textarea::placeholder {
    color: #898989;
    font-family: "GT Walsheim Pro", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left; }
  @media (max-width: 1480px) {
    .form .textarea {
      font-size: 15px;
      height: 50px; }
      .form .textarea::placeholder {
        font-size: 15px; } }

.form .wpcf7-not-valid-tip {
  font-size: 0.8em; }

.form .wpcf7 form .wpcf7-response-output {
  margin-left: 0;
  margin-right: 0; }

.form__title {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 24px; }

.form__notice {
  margin-bottom: 25px;
  color: #898989;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.625; }

.form button[type=submit] {
  width: 100%; }

.header-form-wrap {
  background-color: #fff;
  min-width: 425px;
  width: 425px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.09);
  border-radius: 25px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 42px;
  padding-bottom: 42px; }

.insight-form-wrap {
  width: 1080px;
  max-width: calc(100% - 30px);
  margin-left: auto;
  margin-right: auto;
  height: 146px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.13);
  background-color: #fff;
  position: relative;
  z-index: 15;
  display: flex;
  align-items: center; }
  .insight-form-wrap .form {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px; }
    .insight-form-wrap .form .wpcf7-not-valid-tip {
      display: none; }
    .insight-form-wrap .form form .ajax-loader {
      display: none; }
    .insight-form-wrap .form form > p {
      display: flex;
      align-items: center;
      width: 100%; }
    .insight-form-wrap .form form .wpcf7-response-output {
      display: none; }
    .insight-form-wrap .form .input {
      height: 66px; }
    .insight-form-wrap .form__group {
      margin: 0;
      margin-right: 15px;
      flex: 1; }
    .insight-form-wrap .form button[type=submit] {
      max-width: 235px;
      height: 66px;
      min-height: 66px; }
  @media (max-width: 991px) {
    .insight-form-wrap {
      max-width: 100%; }
      .insight-form-wrap .form {
        padding-left: 15px;
        padding-right: 15px; } }
  @media (max-width: 767px) {
    .insight-form-wrap {
      height: auto;
      padding: 30px 0; }
      .insight-form-wrap .form {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .insight-form-wrap .form form > p {
          flex-wrap: wrap;
          width: 100%;
          justify-content: center;
          align-items: center; }
        .insight-form-wrap .form__group {
          margin-left: 0;
          margin-right: 0;
          width: 100%;
          flex: unset;
          margin-bottom: 20px; } }

.pagination-wrap {
  display: flex;
  justify-content: center;
  align-items: center; }
  .pagination-wrap .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none; }
    .pagination-wrap .pagination .page-item {
      margin: 0 6px;
      list-style-type: none; }
      .pagination-wrap .pagination .page-item .page-link {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 38px;
        height: 38px;
        border: 1px solid rgba(0, 0, 0, 0.4);
        color: rgba(0, 0, 0, 0.4);
        font-size: 19px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        transition: 0.3s all ease; }
        .pagination-wrap .pagination .page-item .page-link svg {
          width: 10px;
          height: auto;
          display: block;
          object-fit: contain; }
          .pagination-wrap .pagination .page-item .page-link svg path {
            fill: rgba(0, 0, 0, 0.4);
            transition: 0.3s all ease; }
        .pagination-wrap .pagination .page-item .page-link:hover:not(.active):not(.current):not(.extend) {
          color: #ef7216;
          border-color: #ef7216; }
          .pagination-wrap .pagination .page-item .page-link:hover:not(.active):not(.current):not(.extend) svg path {
            fill: #ef7216; }
        .pagination-wrap .pagination .page-item .page-link.active, .pagination-wrap .pagination .page-item .page-link.current {
          color: #ef7216;
          border-color: #ef7216; }
          .pagination-wrap .pagination .page-item .page-link.active svg path, .pagination-wrap .pagination .page-item .page-link.current svg path {
            fill: #ef7216; }
      .pagination-wrap .pagination .page-item span {
        cursor: default; }

.home-header {
  height: 100vh;
  max-height: 1085px;
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden; }
  .home-header .logo-transparent {
    position: absolute;
    z-index: 15;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    pointer-events: none;
    max-width: 60%; }
  .home-header__bg {
    pointer-events: none; }
    .home-header__bg img {
      z-index: 10;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block; }
  .home-header .overlay {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    background: rgba(0, 0, 0, 0.4); }
  .home-header .video {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    z-index: 11;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%); }
  .home-header .container {
    position: relative;
    z-index: 15;
    height: 100%; }
  .home-header__row {
    padding-top: 50px;
    padding-bottom: 70px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .home-header__subheading {
    text-align: center;
    margin-bottom: 20px; }
  .home-header__heading {
    text-align: center;
    color: #fff;
    letter-spacing: 1.2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .home-header__heading span {
      font-weight: 400; }
    .home-header__heading .heading-inner {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center; }
      .home-header__heading .heading-inner:before {
        position: absolute;
        right: 100%;
        display: inline-block;
        content: "";
        width: 95px;
        height: 2px;
        background-color: #fff;
        margin-right: 25px; }
      .home-header__heading .heading-inner:after {
        position: absolute;
        left: 100%;
        display: inline-block;
        content: "";
        width: 95px;
        height: 2px;
        background-color: #fff;
        margin-left: 25px; }
  .home-header__caption {
    max-width: 765px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 22px;
    color: rgba(255, 255, 255, 0.95);
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    text-align: center; }
  .home-header .btn-wrap {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    .home-header .btn-wrap .btn {
      margin: 0 10px; }
  @media (max-width: 1366px) {
    .home-header__row {
      padding-top: 50px;
      padding-bottom: 20px; }
    .home-header__bg img {
      object-position: left center; } }
  @media (max-width: 991px) {
    .home-header {
      min-width: unset;
      height: auto;
      max-height: unset;
      padding-bottom: 120px;
      padding-top: 165px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .home-header .container {
        height: auto; }
      .home-header__row {
        height: auto;
        padding: 0; } }
  @media (max-width: 660px) {
    .home-header {
      padding-bottom: 60px;
      padding-top: 155px; } }
  @media (max-width: 480px) {
    .home-header {
      min-height: unset; }
      .home-header__heading {
        font-size: 26px; }
      .home-header__subheading {
        font-size: 16px; }
      .home-header__caption {
        font-size: 16px; }
      .home-header .btn-wrap .btn {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px; } }
  @media (max-width: 345px) {
    .home-header__heading {
      font-size: 26px; } }

.page-header {
  height: 100vh;
  max-height: 650px;
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden; }
  .page-header__large {
    min-height: 725px; }
  .page-header__bg {
    pointer-events: none; }
    .page-header__bg img {
      z-index: 10;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block; }
  .page-header .overlay {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    background: rgba(0, 0, 0, 0.4); }
  .page-header .video {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    z-index: 11;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%); }
  .page-header .container {
    position: relative;
    z-index: 15;
    height: 100%; }
  .page-header__row {
    padding-top: 65px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .page-header__subheading {
    text-align: center;
    margin-bottom: 20px; }
  .page-header__heading {
    text-align: center;
    color: #fff;
    letter-spacing: 1.2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .page-header__heading .heading-inner {
      font-weight: 400;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center; }
      .page-header__heading .heading-inner:before {
        position: absolute;
        right: 100%;
        display: inline-block;
        content: "";
        width: 95px;
        height: 2px;
        background-color: #fff;
        margin-right: 25px; }
      .page-header__heading .heading-inner:after {
        position: absolute;
        left: 100%;
        display: inline-block;
        content: "";
        width: 95px;
        height: 2px;
        background-color: #fff;
        margin-left: 25px; }
  .page-header__caption {
    max-width: 512px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 22px;
    color: rgba(255, 255, 255, 0.95);
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    text-align: center; }
  .page-header .btn-wrap {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    .page-header .btn-wrap .btn {
      margin: 0 10px; }
  @media (max-width: 1366px) {
    .page-header {
      max-height: 600px; }
      .page-header__large {
        min-height: 640px; }
      .page-header__bg img {
        object-position: left center; } }
  @media (max-width: 991px) {
    .page-header {
      min-height: unset;
      height: auto;
      max-height: unset;
      padding-bottom: 120px;
      padding-top: 165px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .page-header .container {
        height: auto; }
      .page-header__row {
        height: auto;
        padding: 0; } }
  @media (max-width: 660px) {
    .page-header {
      padding-bottom: 60px;
      padding-top: 120px; }
      .page-header .btn-wrap .btn {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px; } }
  @media (max-width: 480px) {
    .page-header {
      min-height: unset; }
      .page-header__heading {
        font-size: 26px; }
      .page-header__subheading {
        font-size: 16px; }
      .page-header__caption {
        font-size: 16px; } }
  @media (max-width: 345px) {
    .page-header__heading {
      font-size: 26px; } }

.cta-section {
  position: relative;
  padding: 113px 0; }
  .cta-section .bg {
    pointer-events: none; }
    .cta-section .bg img {
      z-index: 10;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block; }
  .cta-section .container {
    position: relative;
    z-index: 15; }
  .cta-section .logo-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 23px; }
    .cta-section .logo-wrap img {
      max-width: 120px;
      height: auto;
      display: block;
      object-fit: contain; }
  .cta-section .section-content {
    margin-top: 12px; }
    .cta-section .section-content a {
      color: #fcb249; }
      .cta-section .section-content a:hover {
        text-decoration: underline; }
  .cta-section .btn-wrap {
    margin-top: 27px; }
  @media (max-width: 767px) {
    .cta-section {
      padding: 75px 0; } }
  @media (max-width: 660px) {
    .cta-section .section-content {
      max-width: 280px;
      margin-left: auto;
      margin-right: auto; } }
  @media (max-width: 480px) {
    .cta-section .section-title {
      font-size: 7vw; } }

.cta-image-section {
  position: relative;
  padding: 105px 0; }
  .cta-image-section .bg {
    pointer-events: none; }
    .cta-image-section .bg img {
      z-index: 10;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block; }
  .cta-image-section .container {
    position: relative;
    z-index: 15; }
  .cta-image-section .section-row {
    display: flex; }
  .cta-image-section .col-left {
    width: 65%; }
  .cta-image-section .col-right {
    width: 35%;
    position: relative; }
    .cta-image-section .col-right img {
      position: absolute;
      max-width: 680px;
      transform: translateY(-10%) translateX(-4%); }
  .cta-image-section .section-title {
    max-width: 660px; }
  .cta-image-section .logo-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 23px; }
    .cta-image-section .logo-wrap img {
      max-width: 120px;
      height: auto;
      display: block;
      object-fit: contain; }
  .cta-image-section .section-content {
    margin-top: 12px; }
  .cta-image-section .btn-wrap {
    margin-top: 27px; }
  @media (max-width: 1366px) {
    .cta-image-section .col-right img {
      max-width: 140%;
      transform: translateY(-15%) translateX(-17%); } }
  @media (max-width: 1199px) {
    .cta-image-section .col-right {
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      .cta-image-section .col-right img {
        transform: translateY(5%) translateX(-6%); } }
  @media (max-width: 991px) {
    .cta-image-section .section-row {
      flex-direction: column; }
    .cta-image-section .section-title {
      max-width: 80%; }
    .cta-image-section .section-content {
      max-width: 70%; }
    .cta-image-section .col-left {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center; }
    .cta-image-section .col-right {
      margin-top: 50px;
      max-width: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
      .cta-image-section .col-right img {
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
        position: static;
        transform: none; } }
  @media (max-width: 767px) {
    .cta-image-section {
      padding: 75px 0; }
      .cta-image-section .section-content {
        max-width: 100%; }
      .cta-image-section .section-title {
        max-width: 100%; } }

.promo-video {
  margin-left: auto;
  margin-right: auto;
  display: flex; }
  .promo-video .col-left {
    width: 58.75%;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 165px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    overflow: hidden; }
    .promo-video .col-left .bg {
      pointer-events: none; }
      .promo-video .col-left .bg img {
        z-index: 10;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left;
        display: block; }
    .promo-video .col-left .content {
      max-width: 605px;
      position: relative;
      z-index: 10; }
      .promo-video .col-left .content .section-title {
        position: relative; }
        .promo-video .col-left .content .section-title:after {
          position: relative;
          display: inline-block;
          content: "";
          width: 175px;
          height: 2px;
          background-color: #fff;
          margin-left: 25px;
          transform: translateY(-10px); }
      .promo-video .col-left .content .section-subtitle {
        margin-top: 10px;
        line-height: 1.7;
        font-size: 17px;
        color: rgba(255, 255, 255, 0.9); }
    .promo-video .col-left:after {
      display: block;
      content: "";
      background-color: #fff;
      width: 85px;
      height: 200%;
      position: absolute;
      top: 50%;
      bottom: 0;
      right: -45px;
      z-index: 15;
      transform: translateY(-50%) rotate(21deg); }
  .promo-video .col-right {
    padding-left: 165px;
    width: 41.25%;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .promo-video .col-right .video-link {
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      .promo-video .col-right .video-link .icon {
        overflow: hidden;
        width: 112px;
        height: 112px;
        border-radius: 100%;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
        border: 3px solid #fff;
        background-color: #fcb249;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        transition: 0.3s all ease; }
        .promo-video .col-right .video-link .icon svg {
          width: 15px; }
          .promo-video .col-right .video-link .icon svg path {
            fill: #fff; }
        .promo-video .col-right .video-link .icon:before {
          display: block;
          content: "";
          width: 0;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.4);
          transition: 0.3s all ease; }
        .promo-video .col-right .video-link .icon:hover {
          color: #fff; }
          .promo-video .col-right .video-link .icon:hover:before {
            width: 100%;
            background-color: rgba(255, 255, 255, 0.25); }
      .promo-video .col-right .video-link .label {
        margin-left: 14px;
        color: #7F7F7F;
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        line-height: normal;
        text-align: left;
        text-transform: uppercase;
        letter-spacing: 1.44px;
        transition: 0.3s all ease; }
        .promo-video .col-right .video-link .label:hover {
          color: #000; }
  @media (min-width: 1920px) {
    .promo-video .col-left {
      width: 52%; }
    .promo-video .col-right {
      width: 48%; } }
  @media (max-width: 1440px) {
    .promo-video .col-left {
      padding-left: 70px;
      padding-right: 120px; }
    .promo-video .col-right {
      padding-left: 80px; } }
  @media (max-width: 1240px) {
    .promo-video .col-left .content .section-title:after {
      display: none; } }
  @media (max-width: 1199px) {
    .promo-video .col-left {
      padding-left: 40px; }
      .promo-video .col-left:after {
        width: 100px;
        right: -50px; }
    .promo-video .col-right {
      padding-left: 30px; } }
  @media (max-width: 991px) {
    .promo-video {
      flex-wrap: wrap; }
      .promo-video .col-left {
        width: 100%;
        padding: 65px 15px;
        justify-content: center; }
        .promo-video .col-left:after {
          display: none; }
        .promo-video .col-left .content .section-title {
          text-align: center;
          max-width: 80%;
          margin-left: auto;
          margin-right: auto; }
        .promo-video .col-left .content .section-subtitle {
          margin-top: 14px;
          text-align: center; }
      .promo-video .col-right {
        padding: 40px 15px;
        justify-content: center;
        width: 100%; } }
  @media (max-width: 480px) {
    .promo-video .col-left .content .section-title {
      max-width: 100%; }
    .promo-video .col-right .video-link .icon {
      width: 80px;
      height: 80px; } }

.section-cases {
  padding-top: 120px;
  padding-bottom: 110px; }
  .section-cases .section-title {
    text-align: center; }
  .section-cases .cases-grid {
    margin-top: 56px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px; }
    .section-cases .cases-grid .case-item {
      width: calc(100% / 3 - 30px);
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 30px;
      border: 1px solid #d6d6d6;
      background-color: #fff;
      padding-bottom: 40px;
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 45px; }
      .section-cases .cases-grid .case-item__logo {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 55px;
        width: 135px;
        position: relative; }
        .section-cases .cases-grid .case-item__logo:after {
          position: absolute;
          display: block;
          content: "";
          border-bottom: 2px solid #fcb249;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%); }
        .section-cases .cases-grid .case-item__logo img {
          max-width: 130px;
          max-height: 53px;
          object-fit: contain;
          display: block; }
      .section-cases .cases-grid .case-item__heading {
        color: #6cad4e;
        font-size: 50px;
        font-weight: bold;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        text-transform: uppercase;
        margin-top: 30px;
        margin-bottom: 10px;
        line-height: 1;
        display: block; }
      .section-cases .cases-grid .case-item__subheading {
        text-align: center;
        display: block;
        margin-bottom: 16px;
        color: rgba(0, 0, 0, 0.7); }
      .section-cases .cases-grid .case-item__description {
        text-align: center;
        color: #898989;
        font-size: 17px; }
      .section-cases .cases-grid .case-item__permalink {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: #ef7216;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 0.6px; }
        .section-cases .cases-grid .case-item__permalink .caption {
          position: relative;
          line-height: 1; }
          .section-cases .cases-grid .case-item__permalink .caption:after {
            display: block;
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
            width: 100%;
            height: 1px;
            background-color: #ef7216;
            transition: 0.3s all ease; }
        .section-cases .cases-grid .case-item__permalink .icon {
          margin-left: 6px;
          transition: 0.3s all ease; }
          .section-cases .cases-grid .case-item__permalink .icon svg {
            transform: translateY(-2px);
            height: 12px;
            width: auto;
            display: block; }
        .section-cases .cases-grid .case-item__permalink:hover {
          color: #fcb249; }
          .section-cases .cases-grid .case-item__permalink:hover .caption:after {
            background-color: #fcb249; }
          .section-cases .cases-grid .case-item__permalink:hover .icon {
            transform: translateX(4px); }
  .section-cases .btn-wrap {
    margin-top: 15px; }
  @media (max-width: 1199px) {
    .section-cases .cases-grid {
      margin-left: -10px;
      margin-right: -10px; }
      .section-cases .cases-grid .case-item {
        width: calc(100% / 3 - 20px);
        margin-left: 10px;
        margin-right: 10px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 40px;
        padding-top: 35px; }
        .section-cases .cases-grid .case-item__logo img {
          max-height: 40px; }
        .section-cases .cases-grid .case-item__heading {
          font-size: 40px; }
        .section-cases .cases-grid .case-item__description {
          font-size: 16px; } }
  @media (max-width: 991px) {
    .section-cases {
      padding-top: 80px; }
      .section-cases .cases-grid {
        justify-content: center; }
        .section-cases .cases-grid .case-item {
          width: calc(100% / 2 - 20px); } }
  @media (max-width: 660px) {
    .section-cases .cases-grid .case-item {
      max-width: 420px;
      width: calc(100% - 20px); } }

.section-clients .section-row {
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center; }

.section-clients .col-left {
  position: relative;
  z-index: 15;
  width: 675px;
  height: 220px;
  color: #fff;
  background-image: url(../img/clients-slider.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  align-items: center; }
  .section-clients .col-left:before {
    z-index: 0;
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 50%;
    width: 100vw;
    background-color: #ef7216; }
  .section-clients .col-left .content {
    position: relative;
    z-index: 10; }
  .section-clients .col-left .section-title:after {
    content: "";
    display: block;
    width: 95px;
    height: 3px;
    background-color: #fff;
    margin: 18px 0; }

.section-clients .col-right {
  z-index: 10;
  width: calc(100% - 675px + 160px + 160px);
  margin-left: -160px;
  margin-right: -160px;
  height: 165px;
  background-color: #f6f6f6;
  position: relative;
  display: flex;
  justify-content: flex-start; }
  .section-clients .col-right:before {
    z-index: -1;
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 50%;
    width: 100vw;
    background-color: #f6f6f6; }
  .section-clients .col-right:after {
    z-index: -1;
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    background-color: #f6f6f6; }

.section-clients .clients-slider {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0; }
  .section-clients .clients-slider .swiper-wrapper {
    transition-timing-function: linear !important; }
  .section-clients .clients-slider .swiper-slide {
    width: 200px;
    margin: 0 40px;
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 100%; }
    .section-clients .clients-slider .swiper-slide img {
      height: 55px;
      width: auto;
      display: block;
      object-fit: contain;
      transition: 0.3s all ease;
      filter: grayscale(100%);
      opacity: 0.4; }
      .section-clients .clients-slider .swiper-slide img:hover {
        opacity: 1;
        filter: grayscale(0%); }

@media (max-width: 1680px) {
  .section-clients .col-left {
    width: 535px;
    padding-right: 60px; }
  .section-clients .col-right {
    width: calc(100% - 535px + 160px); } }

@media (max-width: 1440px) {
  .section-clients .clients-slider .swiper-slide {
    width: 150px;
    margin: 0 30px; }
    .section-clients .clients-slider .swiper-slide img {
      height: 38px; } }

@media (max-width: 991px) {
  .section-clients .section-row {
    flex-direction: column; }
  .section-clients .col-left {
    height: auto;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 0;
    padding-right: 0;
    background-color: #ef7216; }
    .section-clients .col-left .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .section-clients .col-left .content .section-title:after {
        margin-left: auto;
        margin-right: auto; }
  .section-clients .col-right {
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
  .section-clients .clients-slider .swiper-slide {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% / 3);
    padding-left: 35px;
    padding-right: 35px; }
    .section-clients .clients-slider .swiper-slide img {
      height: auto;
      max-height: 50px;
      width: 100%;
      object-fit: contain; } }

@media (max-width: 660px) {
  .section-clients .col-left {
    padding-top: 24px;
    padding-bottom: 24px; }
    .section-clients .col-left .section-title:after {
      margin: 12px 0; }
  .section-clients .clients-slider .swiper-slide {
    margin-left: 0;
    margin-right: 0; }
    .section-clients .clients-slider .swiper-slide img {
      max-height: 45px; } }

@media (max-width: 480px) {
  .section-clients .clients-slider .swiper-slide {
    width: calc(100% / 2);
    padding: 0 20px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .section-clients .clients-slider .swiper-slide img {
      max-height: 50px; } }

.section-offers {
  padding-top: 95px;
  padding-bottom: 110px; }
  .section-offers .section-title {
    text-align: center; }
  .section-offers .offers-grid {
    display: flex;
    flex-direction: column;
    margin-top: 32px; }
  .section-offers .offer-row {
    margin-bottom: 100px;
    display: flex;
    align-items: center; }
    .section-offers .offer-row:last-child {
      margin-bottom: 0; }
    .section-offers .offer-row .col-image {
      width: 49.5%; }
      .section-offers .offer-row .col-image img {
        max-width: unset; }
    .section-offers .offer-row .col-content {
      width: 50%; }
      .section-offers .offer-row .col-content .offer-title {
        position: relative; }
        .section-offers .offer-row .col-content .offer-title:after {
          margin: 17px 0;
          display: block;
          content: "";
          position: static;
          width: 75px;
          height: 3px;
          background-color: #ef7216; }
      .section-offers .offer-row .col-content .offer-description {
        color: #898989; }
      .section-offers .offer-row .col-content .btn-wrap {
        margin-top: 25px; }
    .section-offers .offer-row:nth-child(odd) {
      flex-direction: row; }
      .section-offers .offer-row:nth-child(odd) .col-image {
        display: flex;
        justify-content: flex-end;
        align-items: center; }
        .section-offers .offer-row:nth-child(odd) .col-image img {
          transform: translateY(10%); }
      .section-offers .offer-row:nth-child(odd) .col-content {
        padding-left: 60px; }
    .section-offers .offer-row:nth-child(even) {
      flex-direction: row-reverse; }
      .section-offers .offer-row:nth-child(even) .col-image {
        display: flex;
        justify-content: flex-start;
        align-items: center; }
      .section-offers .offer-row:nth-child(even) .col-content {
        width: 49.5%;
        padding-right: 60px;
        transform: translateY(-35px); }
    .section-offers .offer-row:nth-child(3) {
      margin-top: -80px; }
  @media (max-width: 1199px) {
    .section-offers .offer-row {
      margin-bottom: 70px; }
      .section-offers .offer-row .col-image img {
        max-width: 115%; } }
  @media (max-width: 991px) {
    .section-offers {
      padding-top: 70px;
      padding-bottom: 60px; }
      .section-offers .offers-grid {
        margin-top: 65px; }
      .section-offers .offer-row .col-image {
        padding-bottom: 15px; }
      .section-offers .offer-row:nth-child(odd), .section-offers .offer-row:nth-child(even) {
        flex-direction: column; }
        .section-offers .offer-row:nth-child(odd) .col-image, .section-offers .offer-row:nth-child(even) .col-image {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center; }
          .section-offers .offer-row:nth-child(odd) .col-image img, .section-offers .offer-row:nth-child(even) .col-image img {
            transform: none;
            max-width: 100%; }
        .section-offers .offer-row:nth-child(odd) .col-content, .section-offers .offer-row:nth-child(even) .col-content {
          transform: none;
          width: 100%;
          padding: 0; }
          .section-offers .offer-row:nth-child(odd) .col-content .offer-title, .section-offers .offer-row:nth-child(even) .col-content .offer-title {
            text-align: center;
            max-width: 80%;
            margin-left: auto;
            margin-right: auto; }
            .section-offers .offer-row:nth-child(odd) .col-content .offer-title:after, .section-offers .offer-row:nth-child(even) .col-content .offer-title:after {
              margin-left: auto;
              margin-right: auto; }
          .section-offers .offer-row:nth-child(odd) .col-content .offer-description, .section-offers .offer-row:nth-child(even) .col-content .offer-description {
            text-align: center; }
          .section-offers .offer-row:nth-child(odd) .col-content .btn-wrap, .section-offers .offer-row:nth-child(even) .col-content .btn-wrap {
            display: flex;
            justify-content: center;
            align-items: center; }
      .section-offers .offer-row:nth-child(odd) .col-image img {
        transform: none; }
      .section-offers .offer-row:nth-child(even) .col-image img {
        transform: translateX(2%); }
      .section-offers .offer-row:nth-child(3) {
        margin-top: 0; } }

.section-testimonials {
  position: relative;
  padding-top: 180px;
  padding-bottom: 115px; }
  .section-testimonials .bg img {
    pointer-events: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    object-position: right center; }
  .section-testimonials .section-row {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 10; }
  .section-testimonials .col-left {
    width: calc(50% - 30px);
    position: relative; }
    .section-testimonials .col-left .testimonial {
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
      background-color: #fff;
      padding-top: 44px;
      padding-bottom: 42px;
      padding-left: 25px;
      padding-right: 50px;
      position: relative; }
      .section-testimonials .col-left .testimonial__quote {
        height: 30px;
        line-height: 112px;
        color: #072a5f;
        font-size: 130px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal; }
      .section-testimonials .col-left .testimonial__content {
        padding-top: 30px;
        color: #898989;
        font-weight: 400;
        font-style: italic;
        letter-spacing: normal;
        line-height: 1.75;
        text-align: left; }
      .section-testimonials .col-left .testimonial__author {
        margin-top: 10px;
        font-weight: 500; }
      .section-testimonials .col-left .testimonial__position {
        margin-top: 8px;
        color: #898989;
        font-weight: 400;
        font-style: italic;
        letter-spacing: normal;
        text-align: left;
        text-transform: uppercase; }
      .section-testimonials .col-left .testimonial__author-photo {
        position: absolute;
        top: 0;
        right: 0;
        width: 160px;
        height: 160px;
        transform: translateY(-40%) translateX(40%);
        border-radius: 100%; }
    .section-testimonials .col-left .client-item {
      position: relative;
      width: 87px;
      height: 87px;
      border-radius: 100%;
      box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.15);
      border: 1px solid #ffffff; }
      .section-testimonials .col-left .client-item__wrap {
        position: absolute; }
        .section-testimonials .col-left .client-item__wrap:nth-child(1) {
          top: -19%;
          left: -30%; }
        .section-testimonials .col-left .client-item__wrap:nth-child(2) {
          top: 27%;
          left: -43%; }
        .section-testimonials .col-left .client-item__wrap:nth-child(3) {
          top: 68%;
          left: -21%; }
      .section-testimonials .col-left .client-item img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .section-testimonials .col-right {
    width: calc(50% - 70px); }
    .section-testimonials .col-right .section-title:after {
      margin-top: 12px;
      content: "";
      display: block;
      width: 185px;
      height: 3px;
      background-color: #6cad4e; }
    .section-testimonials .col-right .section-description {
      margin-top: 20px;
      color: #898989;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal; }
    .section-testimonials .col-right .permalink {
      margin-top: 20px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: #ef7216;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 700;
      font-style: normal;
      letter-spacing: 0.6px; }
      .section-testimonials .col-right .permalink .caption {
        position: relative;
        line-height: 1; }
        .section-testimonials .col-right .permalink .caption:after {
          display: block;
          content: "";
          position: absolute;
          bottom: -2px;
          left: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background-color: #ef7216;
          transition: 0.3s all ease; }
      .section-testimonials .col-right .permalink .icon {
        margin-left: 6px;
        transition: 0.3s all ease; }
        .section-testimonials .col-right .permalink .icon svg {
          transform: translateY(-2px);
          height: 12px;
          width: auto;
          display: block; }
      .section-testimonials .col-right .permalink:hover {
        color: #fcb249; }
        .section-testimonials .col-right .permalink:hover .caption:after {
          background-color: #fcb249; }
        .section-testimonials .col-right .permalink:hover .icon {
          transform: translateX(4px); }
    .section-testimonials .col-right .clients {
      display: flex;
      margin-top: 24px; }
      .section-testimonials .col-right .clients .become-client {
        width: 70px;
        height: 70px;
        border-radius: 100%;
        border: 4px solid #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.15);
        background-color: #6cad4e;
        color: #fff;
        font-size: 40px;
        transform: translateX(-50px); }
      .section-testimonials .col-right .clients .photo {
        position: relative;
        width: 70px;
        height: 70px;
        border-radius: 100%;
        border: 4px solid #fff;
        box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.15); }
        .section-testimonials .col-right .clients .photo:first-child {
          transform: none; }
        .section-testimonials .col-right .clients .photo:nth-child(1) {
          transform: translateX(-10px); }
        .section-testimonials .col-right .clients .photo:nth-child(2) {
          transform: translateX(-20px); }
        .section-testimonials .col-right .clients .photo:nth-child(3) {
          transform: translateX(-30px); }
        .section-testimonials .col-right .clients .photo:nth-child(4) {
          transform: translateX(-40px); }
        .section-testimonials .col-right .clients .photo img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; }
  @media (max-width: 1199px) {
    .section-testimonials {
      padding-top: 120px;
      padding-bottom: 90px; }
      .section-testimonials .col-left .client-item__wrap:nth-child(1) {
        top: -17%;
        left: -10%; }
      .section-testimonials .col-left .client-item__wrap:nth-child(2) {
        top: 29%;
        left: -24%; }
      .section-testimonials .col-left .client-item__wrap:nth-child(3) {
        top: 75%;
        left: -14%; } }
  @media (max-width: 991px) {
    .section-testimonials {
      padding: 80px 0; }
      .section-testimonials .section-row {
        flex-direction: column-reverse; }
      .section-testimonials .col-left {
        width: 100%; }
        .section-testimonials .col-left .client-item__wrap {
          display: none; }
        .section-testimonials .col-left .testimonial {
          max-width: 90%; }
      .section-testimonials .col-right {
        width: 100%;
        margin-bottom: 45px; } }
  @media (max-width: 565px) {
    .section-testimonials .col-left .testimonial {
      max-width: 100%;
      padding-top: 35px; }
      .section-testimonials .col-left .testimonial__quote {
        height: 15px;
        line-height: 90px;
        font-size: 100px; }
      .section-testimonials .col-left .testimonial__content {
        font-size: 15px; }
      .section-testimonials .col-left .testimonial__author-photo {
        width: 100px;
        height: 100px;
        transform: translateY(-20%) translateX(20%); }
      .section-testimonials .col-left .testimonial__position {
        font-size: 14px; } }

.section-testimonial {
  position: relative;
  padding-top: 120px;
  padding-bottom: 100px; }
  .section-testimonial .bg img {
    pointer-events: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    object-position: right center; }
  .section-testimonial .container {
    position: relative;
    z-index: 10; }
  .section-testimonial .author {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto; }
    .section-testimonial .author img {
      width: 144px;
      height: 144px;
      border-radius: 100%;
      display: block;
      object-fit: contain;
      border: 8px solid #6cad4e; }
  .section-testimonial .feedback {
    max-width: 1090px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
    margin-top: 28px;
    text-align: center;
    color: rgba(0, 0, 0, 0.77);
    font-size: 25px;
    font-weight: bold;
    font-style: italic;
    letter-spacing: normal;
    line-height: 1.65; }
  .section-testimonial .author__name {
    color: #f57f20;
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.44px;
    line-height: normal; }
  .section-testimonial .author__position {
    margin-top: 6px;
    color: #898989;
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    text-align: center; }
  .section-testimonial[data-scheme=white] .author img {
    background-color: #fff;
    border-color: #fff; }
  .section-testimonial[data-scheme=white] .feedback {
    color: #fff; }
  .section-testimonial[data-scheme=white] .author__name {
    color: #fff; }
  .section-testimonial[data-scheme=white] .author__position {
    color: #fff; }
  @media (max-width: 1199px) {
    .section-testimonial {
      padding-top: 100px;
      padding-bottom: 90px; } }
  @media (max-width: 991px) {
    .section-testimonial {
      padding: 80px 0; }
      .section-testimonial .feedback {
        font-size: 22px; } }
  @media (max-width: 767px) {
    .section-testimonial .feedback {
      font-size: 18px; } }

.section-steps {
  padding: 100px 0; }
  .section-steps .section-title {
    text-align: center; }
  .section-steps .section-subtitle {
    text-align: center;
    padding-top: 35px;
    color: #898989; }
  .section-steps .steps-grid {
    margin-top: 42px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -25px;
    margin-right: -25px; }
    .section-steps .steps-grid__item {
      width: calc(100% / 3 - 50px);
      margin-left: 25px;
      margin-right: 25px; }
      .section-steps .steps-grid__item .icon img {
        min-height: 72px;
        width: auto;
        display: block;
        object-fit: contain; }
      .section-steps .steps-grid__item .timeline {
        margin: 25px 0;
        position: relative; }
        .section-steps .steps-grid__item .timeline:after {
          position: absolute;
          top: 50%;
          left: 0;
          display: block;
          content: "";
          width: calc(100% + 50px);
          height: 1px;
          background: #d4d4d4; }
        .section-steps .steps-grid__item .timeline-circle {
          width: 28px;
          height: 28px;
          border: 1px solid #cacaca;
          background-color: #fff;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          z-index: 10; }
          .section-steps .steps-grid__item .timeline-circle:before {
            width: 16px;
            height: 16px;
            background-color: #fcb249;
            border-radius: 100%;
            display: block;
            content: ""; }
      .section-steps .steps-grid__item:nth-child(3n+3) .timeline:after {
        width: 100%; }
      .section-steps .steps-grid__item .step-title {
        color: rgba(0, 0, 0, 0.7); }
        .section-steps .steps-grid__item .step-title:after {
          display: block;
          content: "";
          width: 55px;
          height: 2px;
          background-color: #fcb249;
          margin-bottom: 19px;
          margin-top: 25px; }
        @media (min-width: 992px) {
          .section-steps .steps-grid__item .step-title {
            font-size: 23px; } }
      .section-steps .steps-grid__item .step-description {
        color: #898989;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 1.88; }
  @media (max-width: 1199px) {
    .section-steps {
      padding: 90px 0; }
      .section-steps .steps-grid {
        margin-left: -15px;
        margin-right: -15px; }
        .section-steps .steps-grid__item {
          width: calc(100% / 3 - 30px);
          margin-left: 15px;
          margin-right: 15px; }
          .section-steps .steps-grid__item .step-title {
            font-size: 19px; } }
  @media (max-width: 991px) {
    .section-steps {
      padding: 80px 0; }
      .section-steps .steps-grid {
        margin-left: 0;
        margin-right: 0; }
        .section-steps .steps-grid__item {
          position: relative;
          width: 100%;
          max-width: 480px;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 60px; }
          .section-steps .steps-grid__item:last-child {
            margin-bottom: 0; }
          .section-steps .steps-grid__item .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px; }
          .section-steps .steps-grid__item .step-title {
            text-align: center; }
            .section-steps .steps-grid__item .step-title:after {
              margin-left: auto;
              margin-right: auto; }
          .section-steps .steps-grid__item .step-description {
            text-align: center; }
          .section-steps .steps-grid__item .timeline {
            display: none; } }

.section-news {
  padding-top: 115px;
  padding-bottom: 100px; }
  .section-news .posts-masonry {
    margin-top: 70px;
    display: flex;
    flex-wrap: wrap; }
    .section-news .posts-masonry .post {
      min-height: 275px;
      position: relative;
      margin-bottom: 20px;
      padding-bottom: 40px;
      padding-left: 38px;
      padding-right: 30px;
      padding-top: 30px;
      display: flex;
      align-items: flex-end; }
      .section-news .posts-masonry .post .permalink {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 15; }
      .section-news .posts-masonry .post .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        position: relative;
        z-index: 12; }
        .section-news .posts-masonry .post .content .post-title {
          color: #fff;
          font-size: 22px;
          font-weight: normal; }
        .section-news .posts-masonry .post .content .post-date {
          color: #fff;
          font-size: 15px;
          font-weight: normal;
          margin-bottom: 8px; }
      .section-news .posts-masonry .post img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 5; }
      .section-news .posts-masonry .post:after {
        display: block;
        content: "";
        background-color: rgba(0, 0, 0, 0.15);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 10; }
      .section-news .posts-masonry .post:nth-child(4n+1) {
        width: calc(35% - 10px);
        margin-right: 20px; }
      .section-news .posts-masonry .post:nth-child(4n+2) {
        width: calc(65% - 10px); }
      .section-news .posts-masonry .post:nth-child(4n+3) {
        margin-right: 20px;
        width: calc(65% - 10px); }
      .section-news .posts-masonry .post:nth-child(4n+4) {
        width: calc(35% - 10px); }
  .section-news .posts-grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px; }
  @media (max-width: 1199px) {
    .section-news {
      padding-top: 90px;
      padding-bottom: 80px; }
      .section-news .posts-masonry .post {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 20px; }
        .section-news .posts-masonry .post .content .post-title {
          font-size: 20px; } }
  @media (max-width: 991px) {
    .section-news .posts-masonry {
      margin-left: -10px;
      margin-right: -10px; }
      .section-news .posts-masonry .post {
        width: calc(50% - 20px) !important;
        margin-left: 10px !important;
        margin-right: 10px !important; }
        .section-news .posts-masonry .post .content .post-title {
          font-size: 20px; } }
  @media (max-width: 565px) {
    .section-news .posts-masonry {
      margin-left: auto !important;
      margin-right: auto !important; }
      .section-news .posts-masonry .post {
        width: 100% !important;
        margin-left: auto !important;
        margin-right: auto !important;
        min-height: 200px; }
        .section-news .posts-masonry .post .content .post-title {
          font-size: 18px; } }

.content-section {
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 120px;
  padding-bottom: 95px; }
  .content-section .content {
    margin-top: 26px; }
  .content-section .section-title:after {
    margin-left: 0;
    margin-right: 0;
    left: 0;
    position: static;
    transform: none;
    margin-top: 24px; }
  @media (max-width: 1199px) {
    .content-section {
      padding: 80px 0; } }
  @media (max-width: 767px) {
    .content-section {
      padding: 60px 0; } }

.page-seo-ebook .content-section {
  padding-top: 165px; }
  @media (max-width: 1199px) {
    .page-seo-ebook .content-section {
      padding-top: 90px; } }
  @media (max-width: 767px) {
    .page-seo-ebook .content-section {
      padding-top: 60px; } }

.book-description {
  padding-top: 100px;
  padding-bottom: 100px; }
  .book-description .features-grid {
    margin-top: 35px;
    max-width: 990px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap; }
    .book-description .features-grid .feature-item {
      width: 50%;
      display: flex;
      align-items: center;
      position: relative;
      padding-bottom: 45px;
      margin-top: 45px; }
      .book-description .features-grid .feature-item:nth-child(odd) {
        padding-right: 60px; }
        .book-description .features-grid .feature-item:nth-child(odd):before {
          display: block;
          content: "";
          height: 160px;
          width: 1px;
          background-color: rgba(0, 0, 0, 0.15);
          position: absolute;
          top: 0;
          right: 0; }
        .book-description .features-grid .feature-item:nth-child(odd):after {
          display: block;
          content: "";
          height: 1px;
          width: calc(100% - 60px);
          background-color: rgba(0, 0, 0, 0.15);
          position: absolute;
          bottom: 0;
          left: 0; }
      .book-description .features-grid .feature-item:nth-child(even) {
        padding-left: 60px; }
        .book-description .features-grid .feature-item:nth-child(even):after {
          display: block;
          content: "";
          height: 1px;
          width: calc(100% - 60px);
          background-color: rgba(0, 0, 0, 0.15);
          position: absolute;
          bottom: 0;
          right: 0; }
      .book-description .features-grid .feature-item:last-child:after, .book-description .features-grid .feature-item:nth-last-child(2):after {
        display: none; }
      .book-description .features-grid .feature-item__icon {
        min-width: 150px;
        width: 150px;
        height: 150px;
        background-color: #f58020;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative; }
        .book-description .features-grid .feature-item__icon:before {
          width: 165px;
          height: 165px;
          border: 2px solid #f58020;
          display: block;
          content: "";
          border-radius: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          margin: auto; }
        .book-description .features-grid .feature-item__icon img {
          max-height: 60px;
          width: auto;
          display: block;
          object-fit: contain; }
      .book-description .features-grid .feature-item__info {
        padding-left: 25px; }
      .book-description .features-grid .feature-item__title {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 6px; }
      .book-description .features-grid .feature-item__list {
        display: flex;
        flex-direction: column; }
        .book-description .features-grid .feature-item__list-item {
          display: inline-flex;
          margin-bottom: 15px;
          line-height: 1.75;
          color: #898989;
          font-size: 17px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: normal;
          text-align: left; }
          .book-description .features-grid .feature-item__list-item:last-child {
            margin-bottom: 0; }
          .book-description .features-grid .feature-item__list-item .icon {
            margin-right: 8px;
            min-width: 18px;
            width: 18px;
            height: 18px;
            transform: translateY(3px); }
  @media (max-width: 991px) {
    .book-description {
      padding: 80px 0; }
      .book-description .features-grid .feature-item {
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        margin-top: 0; }
        .book-description .features-grid .feature-item:nth-child(odd), .book-description .features-grid .feature-item:nth-child(even) {
          padding: 40px; }
          .book-description .features-grid .feature-item:nth-child(odd):before, .book-description .features-grid .feature-item:nth-child(even):before {
            display: none; }
          .book-description .features-grid .feature-item:nth-child(odd):after, .book-description .features-grid .feature-item:nth-child(even):after {
            width: 100%; } }
  @media (max-width: 565px) {
    .book-description {
      padding: 60px 0; }
      .book-description .features-grid .feature-item {
        padding: 40px 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .book-description .features-grid .feature-item:last-child:after {
          display: none; }
        .book-description .features-grid .feature-item__info {
          margin-top: 30px;
          padding-left: 0; }
        .book-description .features-grid .feature-item__icon {
          width: 140px;
          height: 140px;
          min-width: 140px; } }

.section-icon-boxes {
  padding-top: 100px;
  padding-bottom: 100px; }
  .section-icon-boxes .boxes-grid {
    margin-top: 70px;
    display: flex;
    flex-wrap: wrap; }
    .section-icon-boxes .boxes-grid .box-item {
      width: calc(100% / 3);
      padding-left: 40px;
      padding-top: 45px;
      padding-right: 60px;
      padding-bottom: 50px;
      transition: 0.3s all ease; }
      .section-icon-boxes .boxes-grid .box-item__icon {
        display: flex;
        align-items: center;
        margin-bottom: 20px; }
        .section-icon-boxes .boxes-grid .box-item__icon svg, .section-icon-boxes .boxes-grid .box-item__icon img {
          max-height: 60px;
          height: 60px;
          width: auto;
          display: block;
          object-fit: contain; }
          .section-icon-boxes .boxes-grid .box-item__icon svg path, .section-icon-boxes .boxes-grid .box-item__icon img path {
            transition: 0.3s all ease; }
      .section-icon-boxes .boxes-grid .box-item__title {
        font-size: 24px;
        text-transform: uppercase;
        margin-bottom: 18px; }
      .section-icon-boxes .boxes-grid .box-item__description {
        color: #898989;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        text-align: left; }
      .section-icon-boxes .boxes-grid .box-item:hover {
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.1); }
        .section-icon-boxes .boxes-grid .box-item:hover .box-item__icon svg path {
          fill: #000; }
  @media (max-width: 1199px) {
    .section-icon-boxes {
      padding: 80px 0; }
      .section-icon-boxes .boxes-grid {
        margin-top: 60px; }
        .section-icon-boxes .boxes-grid .box-item {
          width: 50%; } }
  @media (max-width: 991px) {
    .section-icon-boxes .boxes-grid {
      margin-top: 60px; }
      .section-icon-boxes .boxes-grid .box-item {
        width: 50%;
        margin-bottom: 25px;
        padding-top: 40px;
        padding-bottom: 35px;
        padding-left: 25px;
        padding-right: 25px; }
        .section-icon-boxes .boxes-grid .box-item__title {
          font-size: 22px; } }
  @media (max-width: 660px) {
    .section-icon-boxes {
      padding: 60px 0; }
      .section-icon-boxes .boxes-grid .box-item {
        max-width: 420px;
        width: 100%;
        margin-left: auto;
        margin-right: auto; } }

.faq-section {
  background-color: rgba(246, 246, 246, 0.4);
  padding: 110px 0; }
  .faq-section .faq-grid {
    margin-top: 60px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px; }
    .faq-section .faq-grid .column {
      width: calc(50% - 20px);
      margin-left: 10px;
      margin-right: 10px; }
    .faq-section .faq-grid .faq-acc {
      width: 100%;
      margin-bottom: 10px;
      border: 1px solid #e7e7e7;
      background-color: #ffffff;
      border-radius: 15px; }
      .faq-section .faq-grid .faq-acc__header {
        height: 66px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 28px;
        padding-right: 22px; }
        .faq-section .faq-grid .faq-acc__header .action-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin-left: 5px;
          min-width: 30px;
          width: 30px;
          height: 30px;
          border: 1px solid #dbdbdb;
          border-radius: 100%;
          background-color: transparent; }
          .faq-section .faq-grid .faq-acc__header .action-btn svg {
            width: 10px;
            height: auto;
            display: block;
            object-fit: contain; }
          .faq-section .faq-grid .faq-acc__header .action-btn .open {
            transform: translateY(1px); }
          .faq-section .faq-grid .faq-acc__header .action-btn .close {
            display: none; }
        .faq-section .faq-grid .faq-acc__header .caption {
          color: rgba(0, 0, 0, 0.8);
          font-weight: 400;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          text-align: left; }
      .faq-section .faq-grid .faq-acc__body {
        display: none;
        position: relative;
        padding-left: 28px;
        padding-right: 22px;
        padding-top: 22px;
        padding-bottom: 22px;
        color: rgba(0, 0, 0, 0.5);
        font-size: 17px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        text-align: left; }
        .faq-section .faq-grid .faq-acc__body:before {
          display: block;
          content: "";
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: calc(100% - 17px - 17px);
          height: 1px;
          background-color: rgba(0, 0, 0, 0.1); }
      .faq-section .faq-grid .faq-acc.active .faq-acc__header .caption {
        color: #ef7216;
        transition: 0.3s all ease; }
      .faq-section .faq-grid .faq-acc.active .faq-acc__header .action-btn {
        border-color: #ef7216; }
        .faq-section .faq-grid .faq-acc.active .faq-acc__header .action-btn .close {
          display: block; }
          .faq-section .faq-grid .faq-acc.active .faq-acc__header .action-btn .close path {
            transition: 0.3s all ease;
            fill: #ef7216; }
        .faq-section .faq-grid .faq-acc.active .faq-acc__header .action-btn .open {
          display: none; }
  @media (max-width: 1199px) {
    .faq-section {
      padding: 80px 0; }
      .faq-section .faq-grid .faq-acc__header {
        padding-left: 20px;
        padding-right: 20px; }
        .faq-section .faq-grid .faq-acc__header .caption {
          font-size: 16px; }
      .faq-section .faq-grid .faq-acc__body {
        padding: 20px;
        font-size: 15px; } }
  @media (max-width: 991px) {
    .faq-section {
      padding: 60px 0; }
      .faq-section .faq-grid {
        margin-left: auto;
        margin-right: auto; }
        .faq-section .faq-grid .column {
          width: 100%;
          margin-left: auto;
          margin-right: auto; } }

.section-socials {
  padding: 105px 0;
  background-color: #f6f6f6; }
  .section-socials .grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border: 1px solid #e7e7e7; }
    .section-socials .grid__item {
      z-index: 10;
      width: 25%;
      min-height: 125px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border: 1px solid #e7e7e7;
      background-color: #ffffff;
      backface-visibility: hidden;
      transform: scale(1);
      transition: 0.3s all ease; }
      .section-socials .grid__item:hover {
        z-index: 15;
        transform: scale(1.08);
        box-shadow: 3px 4px 25px rgba(0, 0, 0, 0.05); }
      .section-socials .grid__item img {
        display: block;
        object-fit: contain;
        max-width: 65%;
        height: auto; }
  @media (max-width: 991px) {
    .section-socials {
      padding: 80px 0; }
      .section-socials .grid__item {
        min-height: 155px;
        width: 50%; } }
  @media (max-width: 660px) {
    .section-socials {
      padding: 60px 0; }
      .section-socials .grid__item img {
        max-width: 80%; } }

.section-about-section {
  padding: 100px 0;
  position: relative; }
  .section-about-section:after {
    border-bottom: 1px solid rgba(137, 137, 137, 0.4);
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 1px;
    max-width: 1170px; }
  .section-about-section .section-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; }
  .section-about-section .col-left {
    width: calc(50% - 50px);
    max-width: 524px; }
    .section-about-section .col-left .section-title:after {
      margin-top: 15px;
      position: static;
      left: 0;
      transform: none; }
    .section-about-section .col-left .post-content {
      margin-top: 25px; }
      .section-about-section .col-left .post-content p {
        margin-bottom: 2rem; }
        .section-about-section .col-left .post-content p:last-child {
          margin-bottom: 0; }
  .section-about-section .col-right {
    width: calc(50% - 50px);
    display: flex;
    justify-content: flex-end; }
    .section-about-section .col-right .video-wrap {
      display: inline-flex;
      align-items: center;
      position: relative; }
      .section-about-section .col-right .video-wrap .caption {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(calc(100% + 7px));
        color: #ef7216;
        font-weight: 600; }
      .section-about-section .col-right .video-wrap .video-link {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        left: 0;
        top: 50%;
        transform: translateY(-50%) translateX(-50%); }
        .section-about-section .col-right .video-wrap .video-link:after {
          display: block;
          content: "";
          position: absolute;
          width: 130px;
          height: 130px;
          border-radius: 100%;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          pointer-events: none;
          border: 1px solid #6cad4e;
          opacity: .5; }
        .section-about-section .col-right .video-wrap .video-link .icon {
          overflow: hidden;
          width: 112px;
          height: 112px;
          border-radius: 100%;
          box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
          border: 10px solid #fff;
          background-color: #6cad4e;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          transition: 0.3s all ease; }
          .section-about-section .col-right .video-wrap .video-link .icon svg {
            width: 15px; }
            .section-about-section .col-right .video-wrap .video-link .icon svg path {
              fill: #fff; }
          .section-about-section .col-right .video-wrap .video-link .icon:before {
            display: block;
            content: "";
            width: 0;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(255, 255, 255, 0.4);
            transition: 0.3s all ease; }
          .section-about-section .col-right .video-wrap .video-link .icon:hover {
            color: #fff; }
            .section-about-section .col-right .video-wrap .video-link .icon:hover:before {
              width: 100%;
              background-color: rgba(255, 255, 255, 0.25); }
  @media (max-width: 1199px) {
    .section-about-section {
      padding: 80px 0; }
      .section-about-section .col-left {
        width: 100%;
        max-width: 100%; }
      .section-about-section .col-right {
        margin-top: 60px;
        width: 100%;
        display: flex;
        justify-content: center; }
        .section-about-section .col-right .video-wrap .video-link {
          top: 0;
          left: 50%; } }

.section-content-image {
  padding: 100px 0;
  position: relative; }
  .section-content-image:after {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    max-width: 1350px;
    width: 100%; }
  .section-content-image .section-row {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .section-content-image .section-title {
    margin-bottom: 20px; }
    .section-content-image .section-title:after {
      margin-top: 15px; }
  .section-content-image .col-left {
    width: 48%;
    padding-right: 17px; }
  .section-content-image .col-right {
    width: 50%;
    padding-left: 17px;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .section-content-image .col-right img {
      max-width: unset; }
  @media (max-width: 991px) {
    .section-content-image {
      padding: 80px 0; }
      .section-content-image .section-row {
        flex-wrap: wrap; }
      .section-content-image .col-left {
        padding: 0;
        width: 100%; }
      .section-content-image .col-right {
        padding: 0;
        margin-top: 35px;
        width: 100%; }
        .section-content-image .col-right img {
          max-width: 100%; } }
  @media (max-width: 767px) {
    .section-content-image {
      padding: 60px 0; } }

.section-team {
  padding: 110px 0;
  background-color: #FAFAFA; }
  .section-team .section-tittle {
    text-align: center; }
    .section-team .section-tittle:after {
      position: static; }
  .section-team .team-grid {
    margin-top: 55px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px; }
    .section-team .team-grid .team-member {
      position: relative;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
      border: 7px solid #fff;
      cursor: pointer;
      transition: 0.3s all ease; }
      .section-team .team-grid .team-member__wrap {
        width: calc(100% / 5 - 40px);
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 30px; }
        .section-team .team-grid .team-member__wrap .name {
          margin-top: 8px;
          font-size: 18px;
          text-align: center;
          cursor: pointer; }
        .section-team .team-grid .team-member__wrap .position {
          margin-top: 4px;
          color: #f57f20;
          font-weight: 400;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          text-align: center;
          font-size: 16px; }
      .section-team .team-grid .team-member:after {
        display: block;
        content: "";
        padding-bottom: 100%; }
      .section-team .team-grid .team-member.active {
        border-color: #ef7216; }
      .section-team .team-grid .team-member img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        display: block;
        object-position: center;
        position: absolute; }
  .section-team .team-preview {
    margin-top: 12px; }
    .section-team .team-preview .team-member-view {
      display: none;
      align-items: center; }
      .section-team .team-preview .team-member-view.active {
        display: flex; }
      .section-team .team-preview .team-member-view .photo {
        width: 44%;
        position: relative; }
        .section-team .team-preview .team-member-view .photo img {
          width: 100%;
          display: block;
          object-fit: contain; }
      .section-team .team-preview .team-member-view .info {
        padding-left: 45px;
        width: 56%; }
        .section-team .team-preview .team-member-view .info .name {
          text-transform: uppercase; }
          @media (min-width: 1481px) {
            .section-team .team-preview .team-member-view .info .name {
              font-size: 36px; } }
        .section-team .team-preview .team-member-view .info .position {
          margin-top: 8px;
          color: #f57f20;
          font-weight: 400;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          text-align: left; }
        .section-team .team-preview .team-member-view .info__inner {
          margin-top: 20px;
          padding-top: 10px;
          padding-bottom: 12px;
          padding-left: 24px;
          border-left: 4px solid #fcb249; }
        .section-team .team-preview .team-member-view .info .info-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px; }
        .section-team .team-preview .team-member-view .info .socials {
          display: flex;
          align-items: center; }
          .section-team .team-preview .team-member-view .info .socials__label {
            color: #313131;
            font-size: 17px;
            margin-right: 12px; }
          .section-team .team-preview .team-member-view .info .socials ul {
            display: flex; }
            .section-team .team-preview .team-member-view .info .socials ul li .socials-link {
              width: 38px;
              height: 38px;
              border-radius: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #B6B6B6;
              margin: 0 3.5px;
              transition: 0.3s all ease; }
              .section-team .team-preview .team-member-view .info .socials ul li .socials-link svg {
                max-width: 45%;
                max-height: 50%;
                height: auto;
                object-fit: contain; }
                .section-team .team-preview .team-member-view .info .socials ul li .socials-link svg path {
                  fill: #fff; }
              .section-team .team-preview .team-member-view .info .socials ul li .socials-link:hover {
                background-color: #fcb249; }
        .section-team .team-preview .team-member-view .info .info-nav {
          display: flex;
          justify-content: flex-end;
          align-items: center; }
          .section-team .team-preview .team-member-view .info .info-nav .nav-btn {
            width: 38px;
            height: 38px;
            border-radius: 100%;
            border: 2px solid #AFAFAF;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-right: 10px;
            transition: 0.3s all ease; }
            .section-team .team-preview .team-member-view .info .info-nav .nav-btn svg {
              max-width: 9px;
              height: auto;
              display: block;
              object-fit: contain; }
              .section-team .team-preview .team-member-view .info .info-nav .nav-btn svg path {
                transition: 0.3s all ease;
                fill: #AFAFAF; }
            .section-team .team-preview .team-member-view .info .info-nav .nav-btn:hover {
              border-color: #7D7D7D; }
              .section-team .team-preview .team-member-view .info .info-nav .nav-btn:hover svg path {
                fill: #7D7D7D; }
  @media (max-width: 1199px) {
    .section-team {
      padding: 80px 0; }
      .section-team .team-grid {
        margin-left: -10px;
        margin-right: -10px; }
        .section-team .team-grid .team-member__wrap {
          width: calc(100% / 5 - 20px);
          margin-left: 10px;
          margin-right: 10px;
          margin-bottom: 20px; }
      .section-team .team-preview .team-member-view .info {
        padding-left: 35px; }
        .section-team .team-preview .team-member-view .info__inner {
          padding-top: 0;
          padding-bottom: 0; }
        .section-team .team-preview .team-member-view .info .description {
          font-size: 16px; } }
  @media (max-width: 991px) {
    .section-team .container-slider {
      width: 100%;
      max-width: 100%;
      padding: 0; }
    .section-team .team-grid {
      flex-wrap: nowrap; }
      .section-team .team-grid .team-member {
        height: 220px; }
        .section-team .team-grid .team-member.active {
          border-color: #fff; }
        .section-team .team-grid .team-member__wrap {
          width: 220px; }
      .section-team .team-grid .team-member__wrap.swiper-slide-active .team-member {
        border-color: #ef7216; }
    .section-team .team-preview {
      margin-top: 0; }
      .section-team .team-preview .team-member-view {
        flex-wrap: wrap; }
        .section-team .team-preview .team-member-view .photo {
          display: none; }
        .section-team .team-preview .team-member-view .info {
          width: 100%;
          padding-top: 0;
          padding-left: 0; }
          .section-team .team-preview .team-member-view .info .description {
            text-align: center; }
          .section-team .team-preview .team-member-view .info__inner {
            border: 0;
            padding: 0; }
          .section-team .team-preview .team-member-view .info .name {
            display: none; }
          .section-team .team-preview .team-member-view .info .position {
            display: none; }
          .section-team .team-preview .team-member-view .info .info-nav {
            display: none; }
          .section-team .team-preview .team-member-view .info .info-footer {
            flex-wrap: wrap; }
            .section-team .team-preview .team-member-view .info .info-footer .info-nav {
              display: none; }
            .section-team .team-preview .team-member-view .info .info-footer .socials {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 12px; }
              .section-team .team-preview .team-member-view .info .info-footer .socials__label {
                margin-right: 0;
                margin-bottom: 5px;
                text-align: center; } }

.more-about-us {
  padding-top: 125px;
  padding-bottom: 115px; }
  @media (max-width: 991px) {
    .more-about-us {
      padding-top: 90px;
      padding-bottom: 90px; } }
  .more-about-us .map-block {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .more-about-us .map-block .col-left {
      width: 45%; }
      .more-about-us .map-block .col-left .map-wrap {
        position: relative; }
        .more-about-us .map-block .col-left .map-wrap:before {
          position: absolute;
          width: calc(100% + 30px);
          height: calc(100% + 30px);
          bottom: -30px;
          left: -30px;
          display: block;
          content: "";
          background-image: url(../img/triangle.png);
          background-position: left bottom;
          background-repeat: no-repeat; }
        .more-about-us .map-block .col-left .map-wrap:after {
          display: block;
          content: "";
          padding-bottom: 100%; }
      .more-about-us .map-block .col-left iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        display: block;
        object-fit: cover; }
    .more-about-us .map-block .col-right {
      width: 48%; }
      .more-about-us .map-block .col-right .section-title:after {
        margin-bottom: 20px;
        margin-top: 20px; }
      .more-about-us .map-block .col-right .content {
        max-width: 520px;
        color: #898989; }
    @media (max-width: 1366px) {
      .more-about-us .map-block .col-left {
        width: calc(45% - 20px); }
      .more-about-us .map-block .col-right {
        width: calc(55% - 20px); } }
    @media (max-width: 991px) {
      .more-about-us .map-block {
        flex-wrap: wrap; }
        .more-about-us .map-block .col-left {
          width: 100%; }
          .more-about-us .map-block .col-left .map-wrap:after {
            padding-bottom: 56.65%; }
        .more-about-us .map-block .col-right {
          width: 100%;
          margin-top: 70px;
          text-align: center; }
          .more-about-us .map-block .col-right .section-title {
            text-align: center; }
            .more-about-us .map-block .col-right .section-title:after {
              margin-left: auto;
              margin-right: auto; }
          .more-about-us .map-block .col-right .content {
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
            text-align: center; } }
    @media (max-width: 767px) {
      .more-about-us .map-block .col-left .map-wrap:before {
        width: calc(100% + 1530px);
        height: calc(100% + 30px);
        left: -15px;
        right: -15px;
        bottom: -15px;
        background-color: #ef7216; } }
  .more-about-us .testimonial-block {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .more-about-us .testimonial-block .col-left {
      width: 48%; }
      .more-about-us .testimonial-block .col-left .section-title:after {
        margin-bottom: 20px;
        margin-top: 20px; }
      .more-about-us .testimonial-block .col-left .content {
        max-width: 520px;
        color: #898989; }
    .more-about-us .testimonial-block .col-right {
      width: 47%; }
      .more-about-us .testimonial-block .col-right .testimonial {
        box-shadow: 7px 12px 40px rgba(0, 0, 0, 0.06);
        background-color: #fff;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 25px;
        padding-right: 50px;
        position: relative; }
        .more-about-us .testimonial-block .col-right .testimonial__quote {
          height: 30px;
          line-height: 112px;
          color: #072a5f;
          font-size: 130px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: normal; }
        .more-about-us .testimonial-block .col-right .testimonial__content {
          padding-top: 30px;
          color: #898989;
          font-weight: 400;
          font-style: italic;
          letter-spacing: normal;
          line-height: 1.75;
          text-align: left; }
        .more-about-us .testimonial-block .col-right .testimonial__author {
          margin-top: 10px;
          font-weight: 500; }
        .more-about-us .testimonial-block .col-right .testimonial__position {
          margin-top: 8px;
          color: #898989;
          font-weight: 400;
          font-style: italic;
          letter-spacing: normal;
          text-align: left; }
        .more-about-us .testimonial-block .col-right .testimonial__author-photo {
          position: absolute;
          top: 0;
          right: 0;
          width: 160px;
          height: 160px;
          transform: translateY(-40%) translateX(40%);
          border-radius: 100%; }
    @media (max-width: 1366px) {
      .more-about-us .testimonial-block .col-left {
        width: calc(45% - 20px); }
      .more-about-us .testimonial-block .col-right {
        width: calc(55% - 20px); }
        .more-about-us .testimonial-block .col-right .testimonial {
          max-width: 90%; } }
    @media (max-width: 991px) {
      .more-about-us .testimonial-block {
        margin-top: 80px;
        flex-wrap: wrap; }
        .more-about-us .testimonial-block .col-left {
          width: 100%; }
          .more-about-us .testimonial-block .col-left .section-title {
            text-align: center; }
            .more-about-us .testimonial-block .col-left .section-title:after {
              margin-left: auto;
              margin-right: auto; }
          .more-about-us .testimonial-block .col-left .content {
            max-width: 100%;
            text-align: center; }
        .more-about-us .testimonial-block .col-right {
          margin-top: 60px;
          width: 100%; } }
    @media (max-width: 565px) {
      .more-about-us .testimonial-block .col-right .testimonial {
        max-width: 100%;
        padding-top: 35px;
        padding-right: 20px; }
        .more-about-us .testimonial-block .col-right .testimonial__quote {
          height: 15px;
          line-height: 90px;
          font-size: 100px; }
        .more-about-us .testimonial-block .col-right .testimonial__content {
          font-size: 15px;
          padding-top: 35px; }
        .more-about-us .testimonial-block .col-right .testimonial__author-photo {
          width: 100px;
          height: 100px;
          transform: translateY(-20%) translateX(20%); }
        .more-about-us .testimonial-block .col-right .testimonial__position {
          font-size: 14px; } }
  .more-about-us .education-block {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .more-about-us .education-block .col-left {
      width: 45%; }
      .more-about-us .education-block .col-left .photo-wrap {
        position: relative; }
        .more-about-us .education-block .col-left .photo-wrap:before {
          position: absolute;
          width: calc(100% + 30px);
          height: calc(100% + 30px);
          bottom: -30px;
          left: -30px;
          display: block;
          content: "";
          background-image: url(../img/triangle.png);
          background-position: left bottom;
          background-repeat: no-repeat;
          z-index: 10; }
        .more-about-us .education-block .col-left .photo-wrap .video-link {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          display: flex;
          align-items: center;
          justify-content: center; }
          .more-about-us .education-block .col-left .photo-wrap .video-link .icon {
            z-index: 20;
            overflow: hidden;
            width: 112px;
            height: 112px;
            border-radius: 100%;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
            border: 3px solid #fff;
            background-color: #fcb249;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            transition: 0.3s all ease; }
            .more-about-us .education-block .col-left .photo-wrap .video-link .icon svg {
              width: 15px; }
              .more-about-us .education-block .col-left .photo-wrap .video-link .icon svg path {
                fill: #fff; }
            .more-about-us .education-block .col-left .photo-wrap .video-link .icon:before {
              display: block;
              content: "";
              width: 0;
              height: 100%;
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              background-color: rgba(255, 255, 255, 0.4);
              transition: 0.3s all ease; }
            .more-about-us .education-block .col-left .photo-wrap .video-link .icon:hover {
              color: #fff; }
              .more-about-us .education-block .col-left .photo-wrap .video-link .icon:hover:before {
                width: 100%;
                background-color: rgba(255, 255, 255, 0.25); }
        .more-about-us .education-block .col-left .photo-wrap img {
          position: relative;
          z-index: 15; }
    .more-about-us .education-block .col-right {
      width: 48%; }
      .more-about-us .education-block .col-right .section-title:after {
        margin-bottom: 20px;
        margin-top: 20px; }
      .more-about-us .education-block .col-right .content {
        max-width: 520px;
        color: #898989; }
      .more-about-us .education-block .col-right .list {
        margin-top: 20px; }
        .more-about-us .education-block .col-right .list__item {
          display: flex;
          align-items: flex-start;
          margin-bottom: 10px; }
          .more-about-us .education-block .col-right .list__item .icon {
            min-width: 18px;
            width: 18px;
            object-fit: contain;
            display: flex;
            align-items: center;
            margin-right: 8px;
            transform: translateY(5px); }
          .more-about-us .education-block .col-right .list__item .label {
            color: #a3a3a3;
            font-size: 17px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: normal; }
    @media (max-width: 1366px) {
      .more-about-us .education-block .col-left {
        width: calc(45% - 20px); }
      .more-about-us .education-block .col-right {
        width: calc(55% - 20px); } }
    @media (max-width: 991px) {
      .more-about-us .education-block {
        margin-top: 80px;
        flex-wrap: wrap; }
        .more-about-us .education-block .col-left {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center; }
        .more-about-us .education-block .col-right {
          width: 100%;
          margin-top: 70px;
          text-align: center; }
          .more-about-us .education-block .col-right .section-title {
            text-align: center; }
            .more-about-us .education-block .col-right .section-title:after {
              margin-left: auto;
              margin-right: auto; }
          .more-about-us .education-block .col-right .content {
            margin-left: auto;
            margin-right: auto;
            max-width: 100%;
            text-align: center; }
          .more-about-us .education-block .col-right .list {
            max-width: 420px;
            margin-left: auto;
            margin-right: auto; }
            .more-about-us .education-block .col-right .list__item .label {
              text-align: left; } }
    @media (max-width: 767px) {
      .more-about-us .education-block .col-left .photo-wrap:before {
        width: calc(100% + 1530px);
        height: calc(100% + 30px);
        left: -15px;
        right: -15px;
        bottom: -15px;
        background-color: #ef7216; } }

.section-webinars {
  padding-top: 100px;
  padding-bottom: 110px; }
  .section-webinars .webinars {
    margin-top: 85px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-right: -30px; }
    .section-webinars .webinars .webinar {
      width: calc(50% - 60px);
      margin-left: 30px;
      margin-right: 30px;
      margin-bottom: 70px; }
      .section-webinars .webinars .webinar__thumbnail {
        position: relative; }
        .section-webinars .webinars .webinar__thumbnail:after {
          display: block;
          content: "";
          padding-bottom: 65%; }
        .section-webinars .webinars .webinar__thumbnail img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .section-webinars .webinars .webinar__thumbnail .type {
          position: absolute;
          z-index: 15;
          top: 26px;
          left: -10px;
          box-shadow: 0 0 40px rgba(0, 0, 0, 0.13);
          background-color: #fcb249;
          color: #fff;
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          letter-spacing: 0.64px;
          line-height: normal;
          text-transform: uppercase; }
          .section-webinars .webinars .webinar__thumbnail .type__inner {
            width: 150px;
            height: 34px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center; }
            .section-webinars .webinars .webinar__thumbnail .type__inner:before {
              display: block;
              content: "";
              background-image: url(../img/webinar-angle.svg);
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              width: 10px;
              height: 6px;
              position: absolute;
              bottom: 0;
              left: 0;
              transform: translateY(100%); }
      .section-webinars .webinars .webinar__info {
        background-color: #fff;
        width: calc(100% - 75px);
        margin-top: -60px;
        z-index: 15;
        position: relative;
        padding-top: 35px; }
        .section-webinars .webinars .webinar__info .btn {
          height: 56px; }
      .section-webinars .webinars .webinar__title {
        margin-bottom: 35px;
        text-transform: uppercase; }
  @media (max-width: 1199px) {
    .section-webinars {
      padding-top: 80px;
      padding-bottom: 80px; }
      .section-webinars .webinars {
        margin-top: 50px;
        margin-left: -20px;
        margin-right: -20px; }
        .section-webinars .webinars .webinar {
          width: calc(50% - 40px);
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 50px; }
          .section-webinars .webinars .webinar__info {
            width: calc(100% - 15px);
            padding-top: 30px; }
          .section-webinars .webinars .webinar__title {
            margin-bottom: 30px; } }
  @media (max-width: 991px) {
    .section-webinars .webinars .webinar__info {
      margin-top: 0;
      padding-top: 15px; }
    .section-webinars .webinars .webinar__title {
      margin-bottom: 15px;
      font-size: 18px; } }
  @media (max-width: 767px) {
    .section-webinars .webinars {
      margin-left: 0;
      margin-right: 0; }
      .section-webinars .webinars .webinar {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 70px; }
        .section-webinars .webinars .webinar:last-child {
          margin-bottom: 0; }
        .section-webinars .webinars .webinar__info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
        .section-webinars .webinars .webinar__title {
          text-align: center; } }

.form-section {
  padding: 60px 0; }
  .form-section .form {
    max-width: 840px;
    margin-left: auto;
    margin-right: auto; }
  .form-section .form__group-row {
    display: flex;
    justify-content: space-between; }
    .form-section .form__group-row .form__group {
      width: calc(50% - 10px); }
  @media (max-width: 660px) {
    .form-section .form__group-row {
      flex-wrap: wrap; }
      .form-section .form__group-row .form__group {
        width: 100%; } }

.case-studies-section {
  padding-bottom: 100px; }
  .case-studies-section .cases-grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: 110px;
    margin-left: -15px;
    margin-right: -15px; }
  .case-studies-section .pagination-wrap .pagination {
    margin-top: 35px; }
  .case-studies-section .pagination-wrap .cs-lazy-load {
    margin-top: 35px; }
  .case-studies-section .service-filter {
    position: relative;
    z-index: 35;
    margin-left: auto;
    margin-right: auto;
    width: 720px;
    max-width: 100%;
    height: 150px;
    margin-top: -75px;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.13);
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center; }
    .case-studies-section .service-filter label {
      padding: 0 32px;
      width: 100%; }
    .case-studies-section .service-filter .choices {
      width: 100%;
      height: 66px;
      background-color: #fff;
      position: relative;
      z-index: 15; }
      .case-studies-section .service-filter .choices[data-type*=select-one]:after {
        background-image: url(../img/down.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        width: 14px;
        height: 12px;
        border: none;
        margin: 0;
        top: 50%;
        transform: translateY(-50%);
        right: 25px;
        position: absolute; }
      .case-studies-section .service-filter .choices.is-open:after {
        transform: rotate(180deg) translateY(50%); }
      .case-studies-section .service-filter .choices__inner {
        padding: 0;
        height: 100%;
        display: flex;
        background-color: #fff;
        align-items: center;
        border: 1px solid #d8d8d8;
        border-radius: 0; }
      .case-studies-section .service-filter .choices__list--single {
        padding: 0; }
      .case-studies-section .service-filter .choices__list--dropdown {
        border-radius: 0;
        border: 1px solid #d8d8d8; }
        .case-studies-section .service-filter .choices__list--dropdown .choices__item--selectable {
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-bottom: 1px solid #d8d8d8;
          color: rgba(0, 0, 0, 0.65);
          border-radius: 0; }
          .case-studies-section .service-filter .choices__list--dropdown .choices__item--selectable:hover {
            color: #000; }
          .case-studies-section .service-filter .choices__list--dropdown .choices__item--selectable:after {
            display: none !important; }
      .case-studies-section .service-filter .choices__item {
        padding-left: 25px; }
        .case-studies-section .service-filter .choices__item--selectable {
          border-radius: 0;
          color: #000;
          font-size: 18px;
          font-weight: 400;
          line-height: normal;
          text-align: left;
          text-transform: uppercase;
          font-style: normal;
          letter-spacing: 0.72px; }
  @media (max-width: 991px) {
    .case-studies-section .cases-grid {
      margin-left: auto;
      margin-right: auto;
      margin-top: 85px; }
    .case-studies-section .pagination-wrap .pagination {
      margin-top: 15px; } }
  @media (max-width: 660px) {
    .case-studies-section .cases-grid {
      margin-top: 60px; }
    .case-studies-section .service-filter {
      height: 100px; }
      .case-studies-section .service-filter label {
        padding: 0 15px; }
      .case-studies-section .service-filter .choices[data-type*=select-one]:after {
        right: 15px; }
      .case-studies-section .service-filter .choices__item {
        padding-left: 15px; }
        .case-studies-section .service-filter .choices__item--selectable {
          font-size: 15px; } }

.case-study {
  position: relative;
  width: calc(100% / 2 - 30px);
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 80px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.04);
  border: 1px solid #e4e4e4;
  background-color: #ffffff; }
  .case-study__thumbnail {
    position: relative;
    display: block; }
    .case-study__thumbnail:after {
      display: block;
      content: "";
      padding-bottom: 59.65%; }
    .case-study__thumbnail > img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .case-study__thumbnail .type {
      position: absolute;
      z-index: 15;
      top: 30px;
      left: -13px;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.13);
      background-color: #97be64;
      color: #fff;
      font-size: 19px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: 0.64px;
      line-height: normal;
      text-transform: uppercase; }
      .case-study__thumbnail .type__inner {
        min-width: 245px;
        padding-left: 15px;
        padding-right: 15px;
        height: 56px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center; }
        .case-study__thumbnail .type__inner:before {
          display: block;
          content: "";
          background-image: url(../img/case-study-angle.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          width: 13px;
          height: 12px;
          position: absolute;
          bottom: 0;
          left: 0;
          transform: translateY(100%); }
  .case-study__info {
    display: block;
    padding: 95px 50px; }
  .case-study__logo {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    z-index: 15;
    padding-left: 25px;
    padding-right: 25px;
    height: 110px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.04);
    border: 1px solid #e4e4e4;
    background-color: #ffffff;
    display: inline-flex;
    justify-content: center;
    align-items: center; }
    .case-study__logo img {
      object-fit: contain;
      display: block;
      max-height: 70%;
      width: auto; }
  .case-study__title {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 52px; }
    .case-study__title:after {
      width: 90px;
      bottom: -25px; }
  .case-study__excerpt {
    text-align: center; }
  .case-study .btn-wrap {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-top: 25px;
    transform: translateX(-50%) translateY(50%); }
    .case-study .btn-wrap .btn {
      min-width: 295px; }
  @media (max-width: 1199px) {
    .case-study {
      margin-bottom: 95px; }
      .case-study__thumbnail .type {
        top: 15px;
        font-size: 16px;
        height: 50px;
        min-width: 250px; }
        .case-study__thumbnail .type__inner {
          height: 50px; }
      .case-study__logo {
        height: 86px; }
        .case-study__logo img {
          max-height: 45px; }
      .case-study__info {
        padding: 75px 40px; } }
  @media (max-width: 991px) {
    .case-study {
      width: 100%;
      margin-left: auto;
      margin-right: auto; } }
  @media (max-width: 660px) {
    .case-study__title {
      font-size: 20px; }
    .case-study__info {
      padding: 68px 20px; } }

.case-study-wide {
  position: relative;
  width: calc(100% - 30px);
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 100px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.04);
  border: 1px solid #e4e4e4;
  background-color: #ffffff; }
  .case-study-wide__thumbnail {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .case-study-wide__thumbnail .type {
      transform: translateX(-14px);
      width: auto;
      z-index: 15;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.13);
      background-color: #97be64;
      color: #fff;
      font-size: 19px;
      font-weight: bold;
      font-style: normal;
      letter-spacing: 0.64px;
      line-height: normal;
      text-transform: uppercase; }
      .case-study-wide__thumbnail .type__inner {
        min-width: 245px;
        padding-left: 15px;
        padding-right: 15px;
        height: 56px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center; }
        .case-study-wide__thumbnail .type__inner:before {
          display: block;
          content: "";
          background-image: url(../img/case-study-angle.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          width: 13px;
          height: 12px;
          position: absolute;
          bottom: 0;
          left: 0;
          transform: translateY(100%); }
  .case-study-wide__logo {
    z-index: 15;
    padding-left: 25px;
    padding-right: 25px;
    height: 110px;
    background-color: #ffffff;
    display: inline-flex;
    justify-content: center;
    align-items: center; }
    .case-study-wide__logo img {
      object-fit: contain;
      display: block;
      max-height: 70%;
      width: auto; }
  .case-study-wide__info {
    display: block;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 70px;
    padding-top: 25px; }
  .case-study-wide__title {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 52px; }
    .case-study-wide__title:after {
      width: 90px;
      bottom: -25px; }
  .case-study-wide__excerpt {
    text-align: center;
    color: #898989; }
    .case-study-wide__excerpt b, .case-study-wide__excerpt strong {
      color: #000; }
  .case-study-wide .btn-wrap {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-top: 25px;
    transform: translateX(-50%) translateY(50%); }
    .case-study-wide .btn-wrap .btn {
      min-width: 295px; }
  @media (max-width: 1199px) {
    .case-study-wide {
      margin-bottom: 95px; }
      .case-study-wide__thumbnail .type {
        font-size: 16px;
        height: 50px;
        min-width: 250px; }
        .case-study-wide__thumbnail .type__inner {
          height: 50px; }
      .case-study-wide__logo {
        height: 86px; }
        .case-study-wide__logo img {
          max-height: 45px; }
      .case-study-wide__info {
        padding-left: 40px;
        padding-right: 40px; } }
  @media (max-width: 991px) {
    .case-study-wide {
      width: 100%;
      margin-left: auto;
      margin-right: auto; } }
  @media (max-width: 660px) {
    .case-study-wide__info {
      padding-left: 20px;
      padding-right: 20px; }
    .case-study-wide__excerpt {
      color: #000; }
      .case-study-wide__excerpt b {
        font-weight: normal; }
    .case-study-wide__logo img {
      max-height: 70px; } }
  @media (max-width: 480px) {
    .case-study-wide__thumbnail {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 20px; }
    .case-study-wide__logo {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 15px; } }

.results-in-numbers {
  padding: 70px 0; }
  .results-in-numbers .result-boxes {
    display: flex;
    flex-wrap: wrap;
    margin-left: -44px;
    margin-right: -44px; }
    .results-in-numbers .result-boxes .result-box {
      width: calc(100% / 3 - 88px);
      margin-left: 44px;
      margin-right: 44px;
      margin-top: 20px;
      margin-bottom: 20px;
      border: 1px solid;
      background-color: #fdfdfd;
      padding: 65px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .results-in-numbers .result-boxes .result-box__percent {
        text-align: center;
        font-weight: bold;
        font-style: normal;
        letter-spacing: normal;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 65px; }
        .results-in-numbers .result-boxes .result-box__percent:after {
          content: "";
          display: block;
          width: 135px;
          height: 2px;
          margin-bottom: 20px;
          margin-top: 12px; }
        .results-in-numbers .result-boxes .result-box__percent span {
          font-size: 70%;
          transform: translateY(-13%);
          margin-left: 4px; }
      .results-in-numbers .result-boxes .result-box__heading {
        text-align: center;
        color: #000;
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        letter-spacing: normal;
        line-height: 1.35;
        text-transform: uppercase;
        max-width: 200px;
        margin-left: auto;
        margin-right: auto; }
      .results-in-numbers .result-boxes .result-box:nth-child(3n+1) {
        border-color: #fcb249; }
        .results-in-numbers .result-boxes .result-box:nth-child(3n+1) .result-box__percent {
          color: #fcb249; }
          .results-in-numbers .result-boxes .result-box:nth-child(3n+1) .result-box__percent:after {
            background-color: #fcb249; }
      .results-in-numbers .result-boxes .result-box:nth-child(3n+2) {
        border-color: #97be64; }
        .results-in-numbers .result-boxes .result-box:nth-child(3n+2) .result-box__percent {
          color: #97be64; }
          .results-in-numbers .result-boxes .result-box:nth-child(3n+2) .result-box__percent:after {
            background-color: #97be64; }
      .results-in-numbers .result-boxes .result-box:nth-child(3n+3) {
        border-color: #ef7216; }
        .results-in-numbers .result-boxes .result-box:nth-child(3n+3) .result-box__percent {
          color: #ef7216; }
          .results-in-numbers .result-boxes .result-box:nth-child(3n+3) .result-box__percent:after {
            background-color: #ef7216; }
  @media (max-width: 1440px) {
    .results-in-numbers .result-boxes {
      margin-left: 0;
      margin-right: 0; }
      .results-in-numbers .result-boxes .result-box {
        width: calc(100% / 3 - 40px);
        margin-left: 20px;
        margin-right: 20px; } }
  @media (max-width: 1199px) {
    .results-in-numbers .result-boxes .result-box {
      padding: 50px 15px; } }
  @media (max-width: 991px) {
    .results-in-numbers .result-boxes {
      margin-left: -7.5px;
      margin-right: -7.5px; }
      .results-in-numbers .result-boxes .result-box {
        width: calc(100% / 3 - 15px);
        margin-left: 7.5px;
        margin-right: 7.5px;
        margin-top: 7.5px;
        margin-bottom: 7.5px;
        padding: 40px 15px; }
        .results-in-numbers .result-boxes .result-box__heading {
          font-size: 18px; } }
  @media (max-width: 660px) {
    .results-in-numbers {
      padding: 60px 0; }
      .results-in-numbers .result-boxes {
        justify-content: center; }
        .results-in-numbers .result-boxes .result-box {
          width: calc(50% - 15px); } }
  @media (max-width: 480px) {
    .results-in-numbers .result-boxes .result-box {
      width: 100%;
      padding-top: 25px; }
      .results-in-numbers .result-boxes .result-box__percent {
        font-size: 50px; }
      .results-in-numbers .result-boxes .result-box__heading {
        max-width: 100%;
        font-size: 20px; }
        .results-in-numbers .result-boxes .result-box__heading br {
          display: none; } }

.section-result-lists {
  padding: 100px 0; }
  .section-result-lists .lists-grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px; }
    .section-result-lists .lists-grid .list-item {
      width: calc(100% / 2 - 30px);
      margin-left: 15px;
      margin-right: 15px;
      border: 1px solid #e9e9e9;
      padding-left: 35px;
      padding-right: 25px;
      padding-top: 40px;
      padding-bottom: 35px; }
      .section-result-lists .lists-grid .list-item .section-title:after {
        margin-top: 18px; }
    .section-result-lists .lists-grid .list {
      margin-top: 25px; }
      .section-result-lists .lists-grid .list__item {
        display: inline-flex;
        margin-bottom: 15px;
        line-height: 1.75;
        color: #898989;
        font-size: 17px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        text-align: left; }
        .section-result-lists .lists-grid .list__item:last-child {
          margin-bottom: 0; }
        .section-result-lists .lists-grid .list__item .icon {
          margin-right: 8px;
          min-width: 18px;
          width: 18px;
          height: 18px;
          transform: translateY(3px); }
  @media (max-width: 991px) {
    .section-result-lists {
      padding: 80px 0; }
      .section-result-lists .lists-grid {
        margin-left: auto;
        margin-right: auto; }
        .section-result-lists .lists-grid .list-item {
          width: 100%;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 35px;
          padding-left: 20px;
          padding-right: 20px; }
          .section-result-lists .lists-grid .list-item:last-child {
            margin-bottom: 0; }
          .section-result-lists .lists-grid .list-item .list__item {
            font-size: 16px; } }
  .section-result-lists[data-padding-top=""] {
    padding-top: 0; }

.section-campaigns {
  padding: 110px 0;
  background-color: rgba(246, 246, 246, 0.6); }
  .section-campaigns .section-title:after {
    margin-top: 4px; }
  .section-campaigns .campaigns-grid {
    margin-top: 80px; }
  .section-campaigns .campaign-row {
    display: flex;
    position: relative;
    margin-bottom: 100px; }
    .section-campaigns .campaign-row:last-child {
      margin-bottom: 0; }
    .section-campaigns .campaign-row:nth-child(even) {
      flex-direction: row-reverse; }
      .section-campaigns .campaign-row:nth-child(even) .col-content {
        left: 0;
        right: auto; }
    .section-campaigns .campaign-row .col-photo {
      width: 70%;
      position: relative;
      overflow: hidden; }
      .section-campaigns .campaign-row .col-photo .type {
        height: 30px;
        width: 200px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #97be64;
        box-shadow: 15px 26px 40px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #ffffff;
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        line-height: normal;
        transform: rotate(-45deg) translateY(-29%) translateX(-28%);
        z-index: 15; }
    .section-campaigns .campaign-row .col-content {
      width: 46%;
      right: 0;
      bottom: 0;
      position: absolute;
      box-shadow: 7px 12px 40px rgba(0, 0, 0, 0.06);
      border: 1px solid #e9e9e9;
      background-color: #ffffff;
      padding-left: 40px;
      padding-right: 50px;
      padding-top: 50px;
      padding-bottom: 45px;
      transform: translateY(35px); }
      .section-campaigns .campaign-row .col-content .content-title:after {
        margin: 20px 0; }
  @media (max-width: 1199px) {
    .section-campaigns {
      padding: 80px 0; }
      .section-campaigns .campaign-row {
        margin-bottom: 60px; }
        .section-campaigns .campaign-row .col-photo {
          width: 50%; }
          .section-campaigns .campaign-row .col-photo img {
            min-height: 100%;
            object-fit: cover; }
        .section-campaigns .campaign-row .col-content {
          position: static;
          width: 50%;
          transform: none; } }
  @media (max-width: 991px) {
    .section-campaigns .campaigns-grid {
      margin-top: 50px; }
    .section-campaigns .campaign-row {
      flex-wrap: wrap; }
      .section-campaigns .campaign-row .col-photo {
        width: 100%; }
      .section-campaigns .campaign-row .col-content {
        width: 100%; }
        .section-campaigns .campaign-row .col-content img {
          height: auto; } }
  @media (max-width: 660px) {
    .section-campaigns {
      padding: 60px 0; }
      .section-campaigns .campaign-row {
        margin-bottom: 45px; }
        .section-campaigns .campaign-row .col-content {
          padding-top: 35px;
          padding-bottom: 30px;
          padding-left: 20px;
          padding-right: 20px; } }

.case-study-content {
  padding: 110px 0;
  font-size: 20px;
  color: #898989; }
  .case-study-content .video-wrap {
    margin-left: 95px;
    float: right;
    display: inline-flex;
    align-items: center;
    position: relative; }
    .case-study-content .video-wrap .video-link {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      left: 0;
      top: 50%;
      transform: translateY(-50%) translateX(-50%); }
      .case-study-content .video-wrap .video-link:after {
        display: block;
        content: "";
        position: absolute;
        width: 130px;
        height: 130px;
        border-radius: 100%;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        pointer-events: none;
        border: 1px solid #6cad4e;
        opacity: .5; }
      .case-study-content .video-wrap .video-link .icon {
        overflow: hidden;
        width: 112px;
        height: 112px;
        border-radius: 100%;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
        border: 10px solid #fff;
        background-color: #6cad4e;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        transition: 0.3s all ease; }
        .case-study-content .video-wrap .video-link .icon svg {
          width: 15px; }
          .case-study-content .video-wrap .video-link .icon svg path {
            fill: #fff; }
        .case-study-content .video-wrap .video-link .icon:before {
          display: block;
          content: "";
          width: 0;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.4);
          transition: 0.3s all ease; }
        .case-study-content .video-wrap .video-link .icon:hover {
          color: #fff; }
          .case-study-content .video-wrap .video-link .icon:hover:before {
            width: 100%;
            background-color: rgba(255, 255, 255, 0.25); }
    .case-study-content .video-wrap .logo {
      width: 80%;
      max-width: 405px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) translateY(50%);
      z-index: 15;
      padding-left: 25px;
      padding-right: 25px;
      height: 110px;
      box-shadow: 0 0 35px rgba(0, 0, 0, 0.04);
      border: 1px solid #e4e4e4;
      background-color: #ffffff;
      display: inline-flex;
      justify-content: center;
      align-items: center; }
      .case-study-content .video-wrap .logo img {
        object-fit: contain;
        display: block; }
  .case-study-content h1, .case-study-content h2, .case-study-content h3, .case-study-content h4, .case-study-content h5, .case-study-content h6,
  .case-study-content .h1, .case-study-content .h2, .case-study-content .h3, .case-study-content .h4, .case-study-content .h5, .case-study-content .h6 {
    margin-top: 40px;
    text-transform: uppercase; }
    .case-study-content h1:after, .case-study-content h2:after, .case-study-content h3:after, .case-study-content h4:after, .case-study-content h5:after, .case-study-content h6:after,
    .case-study-content .h1:after, .case-study-content .h2:after, .case-study-content .h3:after, .case-study-content .h4:after, .case-study-content .h5:after, .case-study-content .h6:after {
      display: block;
      content: "";
      position: static;
      width: 75px;
      height: 3px;
      background-color: #ef7216;
      margin-top: 15px;
      margin-bottom: 15px; }
  @media (max-width: 1199px) {
    .case-study-content {
      padding: 90px 0;
      font-size: 18px; }
      .case-study-content .video-wrap {
        float: none;
        margin-left: 50px;
        margin-bottom: 50px; } }
  @media (max-width: 991px) {
    .case-study-content {
      font-size: 16px; }
      .case-study-content .video-wrap {
        margin-left: 0; }
        .case-study-content .video-wrap .video-link {
          top: 0;
          left: 50%; } }

.blog-page .page-header__row {
  padding-top: 55px; }

.blog-page .page-header .author-widget .author-photo {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  margin-bottom: 16px; }
  .blog-page .page-header .author-widget .author-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 100%; }
  .blog-page .page-header .author-widget .author-photo .twitter-link {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30px;
    height: 30px;
    border: 2px solid #ffffff;
    border-radius: 100%;
    background-color: #1ba6ff;
    display: flex;
    justify-content: center;
    align-items: center; }
    .blog-page .page-header .author-widget .author-photo .twitter-link svg {
      width: 55%;
      height: auto;
      display: block;
      object-fit: contain; }
      .blog-page .page-header .author-widget .author-photo .twitter-link svg path {
        fill: #fff; }

.blog-page .page-header .author-widget .author-name {
  text-align: center;
  margin-bottom: 12px;
  line-height: 1;
  font-weight: 500; }

.author .blog-page .page-header__heading {
  display: block; }

.blog-page .page-header__caption {
  max-width: 100%; }

@media (max-width: 991px) {
  .blog-page .page-header {
    padding-top: 165px;
    padding-bottom: 165px; }
    .blog-page .page-header__row {
      padding-top: 0; } }

@media (max-width: 660px) {
  .blog-page .page-header {
    padding-bottom: 80px;
    padding-top: 120px; } }

.blog-page .insight-form-wrap {
  margin-top: -78px; }
  @media (max-width: 991px) {
    .blog-page .insight-form-wrap {
      margin-top: 0; } }

@media (max-width: 660px) {
  .blog-page .blog-inner-wrap {
    display: flex;
    flex-direction: column-reverse; } }

.blog-page .posts-grid__wrap {
  padding: 110px 0; }
  .blog-page .posts-grid__wrap .pagination-wrap {
    margin-top: 50px; }
  .blog-page .posts-grid__wrap .filters {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .blog-page .posts-grid__wrap .filters__empty {
      width: 35%; }
    .blog-page .posts-grid__wrap .filters .category-filter {
      display: flex; }
      .blog-page .posts-grid__wrap .filters .category-filter a {
        min-width: 135px;
        padding-left: 10px;
        padding-right: 10px;
        height: 60px;
        border: 1px solid #e7e7e7;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s all ease;
        color: #bab9b9;
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        text-align: center;
        text-transform: uppercase; }
        .blog-page .posts-grid__wrap .filters .category-filter a:not(:last-child) {
          border-right: 0; }
        .blog-page .posts-grid__wrap .filters .category-filter a:hover, .blog-page .posts-grid__wrap .filters .category-filter a.active {
          background-color: #ef7216;
          color: #fff;
          border-color: #ef7216; }
    .blog-page .posts-grid__wrap .filters .tag-filter {
      width: 35%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 2px; }
      .blog-page .posts-grid__wrap .filters .tag-filter a {
        margin: 0 2px;
        padding: 0 14px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: #bab9b9;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        line-height: normal;
        height: 40px;
        border: 1px solid transparent;
        transition: 0.3s all ease; }
        .blog-page .posts-grid__wrap .filters .tag-filter a:hover, .blog-page .posts-grid__wrap .filters .tag-filter a.active {
          border-color: #fcb249;
          color: #fcb249; }
  @media (max-width: 1199px) {
    .blog-page .posts-grid__wrap .filters__empty {
      display: none; } }
  @media (max-width: 991px) {
    .blog-page .posts-grid__wrap {
      padding: 80px 0; }
      .blog-page .posts-grid__wrap .pagination-wrap {
        margin-top: 30px; }
      .blog-page .posts-grid__wrap .filters .category-filter a {
        height: 40px;
        min-width: 82px;
        font-size: 16px; }
      .blog-page .posts-grid__wrap .filters .tag-filter a {
        font-size: 14px;
        height: 35px; } }
  @media (max-width: 660px) {
    .blog-page .posts-grid__wrap {
      padding: 60px 0; }
      .blog-page .posts-grid__wrap .filters {
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .blog-page .posts-grid__wrap .filters .category-filter {
          justify-content: center;
          width: 100%; }
        .blog-page .posts-grid__wrap .filters .tag-filter {
          margin-top: 25px;
          justify-content: center;
          width: 100%; } }

.blog-page .posts-grid {
  margin-top: 85px;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-wrap: wrap; }
  .author .blog-page .posts-grid {
    margin-top: 0; }
  @media (max-width: 767px) {
    .blog-page .posts-grid {
      margin-left: auto;
      margin-right: auto;
      margin-top: 60px; } }

.post-template {
  width: calc(100% / 3 - 30px);
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 37px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.13);
  background-color: #fff; }
  .post-template .post__thumbnail {
    display: block;
    position: relative; }
    .post-template .post__thumbnail:after {
      padding-bottom: 62%;
      display: block;
      content: ""; }
    .post-template .post__thumbnail img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .post-template .post__thumbnail .main-category {
      color: #fff;
      font-size: 15px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      text-transform: uppercase;
      text-align: center;
      padding-left: 14px;
      padding-right: 14px;
      left: 30px;
      bottom: 30px;
      position: absolute;
      z-index: 15;
      height: 30px;
      background-color: #ef7216;
      display: inline-flex;
      justify-content: center;
      align-items: center; }
  .post-template .post__info {
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 30px;
    padding-right: 30px; }
  .post-template .post__date {
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.75);
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
    text-transform: uppercase; }
  .post-template .post__title {
    font-size: 20px;
    text-transform: uppercase;
    line-height: 1.5;
    transition: 0.3s all ease; }
    .post-template .post__title:hover {
      color: #ef7216; }
  @media (max-width: 1199px) {
    .post-template {
      width: calc(100% / 2 - 30px); } }
  @media (max-width: 660px) {
    .post-template {
      width: 100%;
      margin-left: auto;
      margin-right: auto; }
      .post-template .post__title {
        font-size: 18px; }
      .post-template .post__info {
        padding: 28px 15px; }
      .post-template .post__date {
        margin-bottom: 15px; } }

.seo-book-header {
  height: 100vh;
  max-height: 650px;
  display: block;
  position: relative;
  width: 100%; }
  .seo-book-header__bg {
    pointer-events: none; }
    .seo-book-header__bg img {
      z-index: 10;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      margin-left: auto;
      margin-right: auto; }
  .seo-book-header .container {
    position: relative;
    z-index: 10;
    height: 100%; }
  .seo-book-header__subheading {
    margin-bottom: 20px;
    text-align: left; }
  .seo-book-header__row {
    height: 100%;
    display: flex;
    align-items: center; }
  .seo-book-header__heading {
    color: #fff;
    letter-spacing: 1.2px;
    font-weight: normal; }
  .seo-book-header__caption {
    color: rgba(255, 255, 255, 0.95);
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    margin-top: 22px; }
  .seo-book-header .col-left {
    max-width: 480px;
    padding-top: 135px; }
    .seo-book-header .col-left .book {
      display: none; }
  .seo-book-header .col-right {
    padding-left: 15px;
    display: flex;
    align-items: flex-end;
    width: calc(100% - 480px); }
  .seo-book-header .book {
    min-width: 332px;
    transform: translateY(-13%);
    margin-left: 12px; }
  .seo-book-header .header-form-wrap {
    margin-left: 20px;
    margin-top: 40%; }
    .seo-book-header .header-form-wrap .wpcf7 input[type="submit"] {
      width: 100%; }
    .seo-book-header .header-form-wrap .wpcf7 .ajax-loader {
      display: none; }
    .seo-book-header .header-form-wrap .wpcf7 form .wpcf7-response-output {
      display: none; }
  @media (max-width: 1480px) {
    .seo-book-header .book {
      min-width: unset;
      max-width: 260px;
      transform: translateY(20%); }
    .seo-book-header .col-left {
      padding-top: 75px; }
    .seo-book-header .col-right {
      align-items: center; }
    .seo-book-header .header-form-wrap {
      min-width: 365px;
      width: 365px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 35px;
      margin-top: 24%; }
      .seo-book-header .header-form-wrap .form__title {
        font-size: 24px; }
      .seo-book-header .header-form-wrap .form__notice {
        font-size: 14px; } }
  @media (max-width: 1199px) {
    .seo-book-header {
      padding-top: 100px;
      padding-bottom: 40px;
      height: auto;
      min-height: unset;
      max-height: unset; }
      .seo-book-header__row {
        align-items: center; }
      .seo-book-header .col-left {
        width: calc(100% - 365px);
        max-width: 100%;
        padding-top: 45px; }
        .seo-book-header .col-left .book {
          margin-top: 15px;
          transform: none;
          max-width: 180px;
          display: block;
          margin-left: 35px; }
      .seo-book-header .col-right {
        padding-left: 0;
        width: 365px; }
        .seo-book-header .col-right .book {
          display: none; }
      .seo-book-header .header-form-wrap {
        margin-top: 0; } }
  @media (max-width: 991px) {
    .seo-book-header {
      padding-bottom: 60px; }
      .seo-book-header__row {
        flex-direction: column; }
      .seo-book-header .col-left {
        width: 100%;
        max-width: 100%;
        display: flex;
        align-items: center; }
        .seo-book-header .col-left .book {
          margin-top: 0; }
      .seo-book-header .col-right {
        margin-top: 25px;
        width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: center; }
        .seo-book-header .col-right .header-form-wrap {
          margin-left: auto;
          margin-right: auto;
          min-width: unset;
          max-width: 100%;
          width: 480px; } }
  @media (max-width: 767px) {
    .seo-book-header {
      padding-top: 125px; }
      .seo-book-header .col-left {
        padding-top: 0; }
        .seo-book-header .col-left .content {
          margin-left: auto;
          margin-right: auto; }
        .seo-book-header .col-left .book {
          display: none; }
      .seo-book-header__subheading {
        text-align: center; }
      .seo-book-header__heading {
        text-align: center; }
      .seo-book-header__caption {
        text-align: center;
        max-width: 480px;
        margin-left: auto;
        margin-right: auto; } }

.download-webinar-header {
  height: 100vh;
  max-height: 650px;
  display: block;
  position: relative;
  width: 100%; }
  .download-webinar-header__bg {
    pointer-events: none; }
    .download-webinar-header__bg img {
      z-index: 10;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      margin-left: auto;
      margin-right: auto; }
  .download-webinar-header .container {
    position: relative;
    z-index: 10;
    height: 100%; }
  .download-webinar-header__subheading {
    margin-bottom: 20px;
    text-align: left; }
  .download-webinar-header__row {
    height: 100%;
    display: flex;
    align-items: center; }
  .download-webinar-header__heading {
    color: #fff;
    letter-spacing: 1.2px;
    font-weight: normal; }
  .download-webinar-header__caption {
    color: rgba(255, 255, 255, 0.95);
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    margin-top: 22px; }
  .download-webinar-header .col-left {
    padding-top: 100px;
    padding-right: 30px; }
    .download-webinar-header .col-left .book {
      display: none; }
  .download-webinar-header .col-right {
    padding-left: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 425px; }
  .download-webinar-header .header-form-wrap {
    margin-left: 20px;
    margin-top: 65%; }
    .download-webinar-header .header-form-wrap br {
      display: none; }
    .download-webinar-header .header-form-wrap .wpcf7-response-output, .download-webinar-header .header-form-wrap .ajax-loader {
      display: none; }
  @media (max-width: 1480px) {
    .download-webinar-header .col-left {
      padding-top: 75px; }
    .download-webinar-header .col-right {
      align-items: center; }
    .download-webinar-header .header-form-wrap {
      min-width: 365px;
      width: 365px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 35px;
      margin-top: 50%; }
      .download-webinar-header .header-form-wrap .form__title {
        font-size: 24px; }
      .download-webinar-header .header-form-wrap .form__notice {
        font-size: 14px; } }
  @media (max-width: 1199px) {
    .download-webinar-header {
      padding-top: 100px;
      padding-bottom: 40px;
      height: auto;
      min-height: unset;
      max-height: unset; }
      .download-webinar-header__row {
        align-items: center; }
      .download-webinar-header .col-left {
        width: calc(100% - 365px);
        max-width: 100%;
        padding-top: 45px; }
      .download-webinar-header .col-right {
        padding-left: 0;
        width: 365px; }
      .download-webinar-header .header-form-wrap {
        margin-top: 0; } }
  @media (max-width: 991px) {
    .download-webinar-header {
      padding-bottom: 60px; }
      .download-webinar-header__row {
        flex-direction: column; }
      .download-webinar-header .col-left {
        width: 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        padding-right: 0;
        padding-left: 0; }
      .download-webinar-header .col-right {
        margin-top: 25px;
        width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: center; }
        .download-webinar-header .col-right .header-form-wrap {
          margin-left: auto;
          margin-right: auto;
          min-width: unset;
          max-width: 100%;
          width: 480px; } }
  @media (max-width: 767px) {
    .download-webinar-header {
      padding-top: 125px; }
      .download-webinar-header .col-left {
        padding-top: 0; }
        .download-webinar-header .col-left .content {
          margin-left: auto;
          margin-right: auto; }
      .download-webinar-header__subheading {
        text-align: center; }
      .download-webinar-header__heading {
        text-align: center; }
      .download-webinar-header__caption {
        text-align: center;
        max-width: 480px;
        margin-left: auto;
        margin-right: auto; } }

.page-download-webinar .section-content-image {
  padding-top: 215px; }
  @media (max-width: 1480px) {
    .page-download-webinar .section-content-image {
      padding-top: 150px; } }
  @media (max-width: 1199px) {
    .page-download-webinar .section-content-image {
      padding-top: 100px; } }
  @media (max-width: 991px) {
    .page-download-webinar .section-content-image {
      padding-top: 80px; } }
  @media (max-width: 767px) {
    .page-download-webinar .section-content-image {
      padding-top: 60px; } }

.page-download-webinar .book-description .features-grid {
  max-width: 100%; }
  .page-download-webinar .book-description .features-grid .feature-item__list-item .icon {
    display: none; }

.page-seo-ebook .sections-wrap .book-description:first-child, .page-download-webinar .sections-wrap .book-description:first-child {
  padding-top: 210px; }
  @media (max-width: 1199px) {
    .page-seo-ebook .sections-wrap .book-description:first-child, .page-download-webinar .sections-wrap .book-description:first-child {
      padding-top: 100px; } }
  @media (max-width: 991px) {
    .page-seo-ebook .sections-wrap .book-description:first-child, .page-download-webinar .sections-wrap .book-description:first-child {
      padding: 80px 0; } }
  @media (max-width: 565px) {
    .page-seo-ebook .sections-wrap .book-description:first-child, .page-download-webinar .sections-wrap .book-description:first-child {
      padding: 60px 0; } }

.page-template-post-single .page-header__caption {
  text-align: center; }
  .page-template-post-single .page-header__caption .divider {
    margin: 0 10px; }
  .page-template-post-single .page-header__caption a {
    position: relative;
    transition: 0.3s all ease; }
    .page-template-post-single .page-header__caption a:before {
      position: absolute;
      display: block;
      content: "";
      bottom: -2px;
      width: 0;
      height: 1px;
      transition: width 0.55s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      right: 0;
      background-color: #ef7216; }
    .page-template-post-single .page-header__caption a:hover {
      color: #ef7216; }
      .page-template-post-single .page-header__caption a:hover:before {
        width: 100%;
        left: 0; }

@media (max-width: 767px) {
  .page-template-post-single .page-header__bg img {
    object-position: center; } }

.page-template-post-single .post-content-section {
  padding: 100px 0; }
  .page-template-post-single .post-content-section__row {
    display: flex;
    justify-content: space-between; }
  .page-template-post-single .post-content-section .content {
    width: calc(100% - 255px - 45px); }
  .page-template-post-single .post-content-section .sidebar {
    width: 255px; }
    .page-template-post-single .post-content-section .sidebar .author-widget {
      width: 100%;
      border: 1px solid #F2F2F2;
      padding-top: 35px;
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-left: 25px;
      padding-right: 25px;
      margin-bottom: 30px; }
      .page-template-post-single .post-content-section .sidebar .author-widget .author-photo {
        position: relative;
        width: 100px;
        height: 100px;
        border-radius: 100%;
        margin-bottom: 16px; }
        .page-template-post-single .post-content-section .sidebar .author-widget .author-photo img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden;
          border-radius: 100%; }
        .page-template-post-single .post-content-section .sidebar .author-widget .author-photo .twitter-link {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 30px;
          height: 30px;
          border: 2px solid #ffffff;
          border-radius: 100%;
          background-color: #1ba6ff;
          display: flex;
          justify-content: center;
          align-items: center; }
          .page-template-post-single .post-content-section .sidebar .author-widget .author-photo .twitter-link svg {
            width: 55%;
            height: auto;
            display: block;
            object-fit: contain; }
            .page-template-post-single .post-content-section .sidebar .author-widget .author-photo .twitter-link svg path {
              fill: #fff; }
      .page-template-post-single .post-content-section .sidebar .author-widget .author-name {
        text-align: center;
        margin-bottom: 12px;
        line-height: 1;
        font-weight: 500; }
      .page-template-post-single .post-content-section .sidebar .author-widget .author-bio {
        color: #898989;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 1.625;
        text-align: center; }
    .page-template-post-single .post-content-section .sidebar .share-widget {
      padding-left: 25px;
      padding-right: 25px;
      margin-bottom: 55px; }
      .page-template-post-single .post-content-section .sidebar .share-widget .widget-title {
        font-weight: 500;
        margin-bottom: 14px; }
      .page-template-post-single .post-content-section .sidebar .share-widget .a2a_kit {
        display: flex; }
    .page-template-post-single .post-content-section .sidebar .newsletter-widget .widget-title {
      padding-left: 25px;
      padding-right: 25px;
      margin-bottom: 20px;
      display: block;
      font-weight: 500; }
    .page-template-post-single .post-content-section .sidebar .newsletter-widget .form__field {
      display: block;
      margin-bottom: 15px; }
    .page-template-post-single .post-content-section .sidebar .newsletter-widget .input::placeholder {
      font-style: italic; }
    .page-template-post-single .post-content-section .sidebar .newsletter-widget .btn {
      height: 52px;
      font-size: 17px; }
  @media (max-width: 1199px) {
    .page-template-post-single .post-content-section {
      padding: 85px 0; } }
  @media (max-width: 991px) {
    .page-template-post-single .post-content-section {
      padding: 60px 0; }
      .page-template-post-single .post-content-section__row {
        flex-direction: column; }
      .page-template-post-single .post-content-section .content {
        width: 100%; }
      .page-template-post-single .post-content-section .sidebar {
        margin-top: 45px;
        width: 100%;
        max-width: 320px;
        margin-left: auto;
        margin-right: auto; } }

.page-template-post-single .cta-section .section-title {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto; }

.page-template-post-single .cta-section .insight-form-wrap {
  margin-top: 50px; }

.page-template-post-single .related-posts {
  padding: 100px 0; }
  .page-template-post-single .related-posts .posts-grid {
    margin-top: 85px;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 767px) {
      .page-template-post-single .related-posts .posts-grid {
        margin-left: auto;
        margin-right: auto;
        margin-top: 60px; } }
  @media (max-width: 1199px) {
    .page-template-post-single .related-posts {
      padding: 80px 0; } }
  @media (max-width: 991px) {
    .page-template-post-single .related-posts {
      padding: 60px 0; } }

.single-case-studies .page-header {
  max-height: 560px; }
  .single-case-studies .page-header__row {
    padding-top: 85px; }
  @media (max-width: 991px) {
    .single-case-studies .page-header__row {
      padding: 0; } }

@media (max-width: 991px) {
  .post-type-archive-case-studies .page-header {
    padding-top: 165px;
    padding-bottom: 165px; } }

@media (max-width: 660px) {
  .post-type-archive-case-studies .page-header {
    padding-bottom: 120px;
    padding-top: 120px; } }

.template-wrapper {
  position: relative;
  overflow-x: hidden;
  max-width: calc(100vw - var(--scroll-width)); }

.admin-bar {
  padding-top: 32px; }
  @media (max-width: 800px) {
    .admin-bar #wpadminbar {
      overflow: hidden; } }
  .admin-bar .nav-main.fixed {
    top: calc(32px); }
  .admin-bar .nav-side {
    top: 32px;
    height: calc(100vh - 32px); }
  @media (max-width: 782px) {
    .admin-bar {
      padding-top: 46px; }
      .admin-bar .nav-main.fixed {
        top: 46px; }
      .admin-bar .nav-side {
        top: 46px;
        height: calc(100vh - 46px); } }
  @media (max-width: 600px) {
    .admin-bar {
      height: 100vh;
      max-height: calc(var(--vh, 1vh) * 100);
      padding-top: 0; }
      .admin-bar #wpadminbar {
        display: none; }
      .admin-bar .nav-side {
        top: 0;
        height: 100vh; }
      .admin-bar .nav-main.fixed {
        top: 0; } }

@media (min-width: 992px) {
  .page-header + .section-clients,
  .home-header + .section-clients {
    margin-top: -27.5px; } }

@media (min-width: 992px) {
  .page-id-8 .section-clients {
    margin-top: -27.5px; } }

.page-id-8 .section-cases {
  background-color: rgba(246, 246, 246, 0.5); }

.page-id-8 .section-about-section .col-left {
  max-width: 550px; }
  .page-id-8 .section-about-section .col-left .post-content {
    font-size: 17px; }
    .page-id-8 .section-about-section .col-left .post-content p {
      margin-bottom: 1rem; }

.page-id-8 .section-about-section .col-right .video-wrap {
  position: relative; }
  .page-id-8 .section-about-section .col-right .video-wrap:before {
    position: absolute;
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    bottom: -30px;
    right: -30px;
    display: block;
    content: "";
    background-image: url(../img/triangle.png);
    background-position: left bottom;
    background-repeat: no-repeat;
    z-index: -1;
    transform: scaleX(-1); }
  .page-id-8 .section-about-section .col-right .video-wrap:after {
    position: absolute;
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    top: -30px;
    left: -30px;
    display: block;
    content: "";
    background-image: url(../img/triangle.png);
    background-position: left bottom;
    background-repeat: no-repeat;
    z-index: -1;
    transform: scale(1, -1); }

@media (max-width: 1199px) {
  .page-id-8 .section-about-section .col-left {
    max-width: 100%; } }

@media (max-width: 767px) {
  .page-id-8 .section-about-section .col-right {
    margin-top: 20px; }
    .page-id-8 .section-about-section .col-right .video-wrap {
      background-color: #ef7216;
      padding: 15px; }
      .page-id-8 .section-about-section .col-right .video-wrap:before {
        display: none; }
      .page-id-8 .section-about-section .col-right .video-wrap:after {
        display: none; } }

@media (max-width: 1366px) {
  .page-id-179 .page-header__bg img {
    object-position: right center; } }

.page-id-211 .page-header {
  max-height: 500px; }

.page-id-211 .cta-section .section-title {
  text-transform: unset; }

@media (max-width: 1366px) {
  .page-id-259 .page-header__bg img {
    object-position: right center; } }

.page-id-259 .page-header__row {
  padding-top: 45px; }
  @media (max-width: 991px) {
    .page-id-259 .page-header__row {
      padding-top: 0; } }

@media (max-width: 991px) {
  .page-id-259 .page-header {
    padding-bottom: 175px; } }
